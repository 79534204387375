


<!-- 

<a id="scrollUp" href="#/" style="position: fixed; z-index: 2147483647; display: block;"><i class="fa fa-angle-up"></i></a> -->

<section style="width:100%; " class="footer">
    <footer id="sticky-footer" style="background-color:#263d58; " class="py-4 text-white-50 ">
        <div class="container text-center" >
          <p style="color: white">
            Copyright ©<script>document.write(new Date().getFullYear());</script>2020 All rights reserved | Designed and Maintained by 
            <a href="https://www.coresoft.herokuapp.com" target="_blank" style="font-weight: bold;color:white;font-size: 15px;">CoreSoft Technology</a>
        </div>
      </footer>
</section>




