import { Component, OnInit } from '@angular/core';
import  *  as  data  from  '../../assets/config.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.html',
  styleUrls: ['./home.css']
})
export class HomeComponent implements OnInit {

 
  

  constructor() { }  
 

 

  ngOnInit(): void {

    console.log(data);
    
  }


  

}

