

<section id="non-printable" style="height: 35px;
margin-bottom: 0px;
margin-top: -12px;">


<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#/home">Home</a></li>
    <li class="breadcrumb-item"><a href="#/home">View all Purchases</a></li>
    
  </ol>
</nav>


</section>

<section style="height:auto;width:auto;">

<ngx-table [configuration]="configuration"
[data]="data"
[columns]="columns"  (event)="eventEmitted($event)">
</ngx-table>

</section>