import { Component, OnInit,Renderer2, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../services/data.service';
import { items } from './model';





@Component({
  selector: 'app-report-collecton',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class ReportcollectComponent implements OnInit {

  view : boolean = false;
  post_invo = new items(1,[]);
  data : any [] =[];
  pdtlist : any [] = [];
  
  ia : number = 1;
  tot_pay : number = 0;
  tot_due : number = 0;
  total_val : number = 0;
  constructor(public ds : DataService,
     private renderer: Renderer2, private cdr: ChangeDetectorRef  ) {

    
   }  
 

 

  ngOnInit(): void {

   

       
    
    this.get_data();
  

  }

  get_data()
  {
    this.ds.collection_report(this.ds.report_demo)
    .subscribe((jsonData) => { this.getjson(jsonData)
                     },(err) => console.error(err),
                   
                     );
 
  }
  getjson(json:any)
  {

    console.log('js',json);
    this.data = json;

    for(var ix = 0; ix < this.data.length ; ix++)
    {

  
    this.auto_push();

    this.post_invo.si = 1
    this.post_invo.item[ix].SI = ix+1;
    this.post_invo.item[ix].CUS_NAME = this.data[ix].CUS_NAME;
    this.post_invo.item[ix].DUE_AMT = this.data[ix].DUE_AMT;
    this.post_invo.item[ix].ENTRY_DATE = this.data[ix].ENTRY_DATE;
    this.post_invo.item[ix].ENTRY_NO = this.data[ix].ENTRY_NO;
    this.post_invo.item[ix].GRAND_TOTAL = this.data[ix].GRAND_TOTAL;
    this.post_invo.item[ix].PAYED_AMT = this.data[ix].PAYED_AMT;


    }
     
       this.auto_push();
       this.auto_push();

           for(var ixc = 0; ixc < this.post_invo.item.length ; ixc++)
    {
      
      this.total_val =  this.total_val + this.post_invo.item[ixc].GRAND_TOTAL;
      this.tot_pay =  this.tot_pay + this.post_invo.item[ixc].PAYED_AMT;
      this.tot_due =  this.tot_due + this.post_invo.item[ixc].DUE_AMT;

      

    }  

    this.post_invo.item[ix+1].GRAND_TOTAL = this.total_val
    this.post_invo.item[ix+1].PAYED_AMT = this.tot_pay
    this.post_invo.item[ix+1].DUE_AMT = this.tot_due
    this.post_invo.item[ix+1].CUS_NAME = 'Total';
    this.view = true;
    }

  
  auto_push()
  {
        this.post_invo.item.push({ SI:null,CUS_NAME:null,ENTRY_DATE:null,ENTRY_NO:null,GRAND_TOTAL:null,
          PAYED_AMT:null,DUE_AMT:null})
  }
   
  }



  







  



