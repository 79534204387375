import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { required, requiredTrue,min, maxLength, isAlphabetical,
   sqlObjectName, between, invalidValues, startsWith, conditionalValidator} from 'ng-easy-validation';
@Component({
  selector: 'app-admin-main-page',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class Admin_main_page_Component implements OnInit {



  constructor(private ds : DataService  ) {

    
   }  
 

 

  ngOnInit(): void {

    
    
  }

  add_pro_dir()
  {
    console.log('iam')
  }

}





 


