<section id="non-printable" style="height: 35px;
margin-bottom: 0px;
margin-top: -12px;">


<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#/home">Home</a></li>
    <li class="breadcrumb-item"><a href="#/home">Update Product</a></li>
    
  </ol>
</nav>


</section>
<section *ngIf="view_head" style="width: 100%;height: 100%;background-color:#d0d2af;padding: 10px;">


  <form [formGroup]="productForm"> 


    <div class="row">


                    <div class="form-group col-md-6 col-sm-6 col-lg-4 col-xs-12">
                        <label >ID</label>
                        <input id="product_id" #box_ar formControlName="id"  ngui-auto-complete type="text" class="form-control"  
                        [source]="arrayOfproductValues"   placeholder="Description Code" 
                         (blur)="article_blur(box_ar.value)"
                                      list-formatter="ID -- DESCRIPTION (BAR_CODE) "
                                      display-property-name="ID">
                        <small [hidden]="productForm.controls.id.valid" class="text-danger">
                                                              id is required.
                                                          </small>
                   </div> 



                   <div class="form-group col-md-6 col-sm-6 col-lg-4 col-xs-12">
                    <label >Description</label>
                    <input id="product_name" #box_ar_n formControlName="description"  (keyup)="desc_blur(box_ar_n.value)"  type="text" class="form-control"  
                     placeholder="Description">
                  
               </div> 



                   <div class="form-group col-md-2 col-sm-2 col-lg-2 col-xs-12">
                      <label> Unit </label>
                      <select class="form-control" formControlName="unit" >
                                                  
                                                                <option *ngFor="let unit of units" [value]="unit">{{unit}}</option>
                                                                </select>

                    </div>

            
              

           

                    <div class="form-group col-md-4 col-sm-4 col-lg-2 col-xs-12">
                            <label >BAR Code</label>
                            <input id="bar_code" formControlName="bar_code" type="text" class="form-control" placeholder="Bar code">
                            <small [hidden]="productForm.controls.bar_code.valid" class="text-danger">
                              hsn is required.
                                                               </small>
                        </div>

<!--                          
                <div class="form-group col-md-4 col-sm-4 col-lg-2 col-xs-12">
                    <label> MRP </label>
                    <input  formControlName="mrp_price"   type="number"  class="form-control" 
                    placeholder="mrp price">
                  </div>
                 -->

                    
         
                
                  <div class="form-group col-md-4 col-sm-4 col-lg-2 col-xs-12">
                    <label> Price </label>
                    <input  formControlName="price"   type="number"  class="form-control" placeholder="price">
                  </div>
                
            
            
                

           
    </div> 
</form>

        <div class="row">
          <div class="form-group col-md-4 col-sm-4 col-lg-2 col-xs-12">
            <button class="btn btn-info" style="margin-left:80%;"  
            (click)="Update_all()">Update</button>
              </div>
              <div class="form-group col-md-4 col-sm-4 col-lg-2 col-xs-12">
            <button class="btn btn-danger" style="margin-left:80%;"  
            (click)="Delete_all()">Delete</button> </div>

          </div>

</section>







