
<section  id="printy" style="width:100%;margin: 0;
font-family: Verdana, Geneva, Tahoma, sans-serif;background-color: red; "> 


  <label style="text-align: center; font-size: 24px; font-weight: bold; font-family: Calibri;line-height: 20px;">
    {{this.ds.COMPANY_NAME}}
  </label> <br>

   <label style="text-align: center; font-size: 20px; font-weight: bold; font-family: Calibri;line-height: 6px;">
    {{this.ds.COMPANY_ADR_1}} ,  {{this.ds.COMPANY_ADR_2}}
  </label>  <br>
     <!-- <label style="text-align: center; font-size: 20px; font-weight: bold; font-family: Calibri;line-height: 10px;">
     
  </label> <br> -->

     <label style="text-align: center; font-size: 18px; font-weight: bold;
     font-family: Calibri;line-height: 8px;">
  Ph : {{this.ds.COMPANY_PH}} , {{this.ds.COMPANY_PH2}}
  </label> <br> 
 
 

     <label  style="width:50%;height:14px;display:inline-block;font-size: 14px;font-weight: bold;line-height: 8px;">  Date  : 
           {{this.ds.i_m.heads.INVOICE_DATE  | date:'fullDate' }} </label>

          

            <label  style="height:16px;display:inline-block;width: 50%;font-size: 14px;font-weight: bold;line-height: 8px; "> 
              Bill No :  {{this.ds.i_m.heads.INVOICE_NUMBER}} </label>

  <label  style="width:49%;height:14px;display:inline-block;font-size: 14px;font-weight: bold;line-height: 8px;">  To  : 
    {{this.ds.i_m.heads.CUS_NAME}} </label>

            <label  style="width:50%;height:14px;display:inline-block;font-size: 14px;font-weight: bold;line-height: 8px;">
                Counter  :  1 </label>

 


   <table>
                <thead>
                    <tr class="qwe" style="border-bottom: 1px dotted black;">
                        <th class="price" style="width: 4%;">#</th>
                        <th class="description" style="font-size: 12px;width: 40%;font-weight: bold;">Description</th>
                        <th class="price" style="font-size: 12px;width: 7%;">Qty</th>
                        <th class="price" style="font-size: 12px;width: 8%;">Unit</th> 
                        <!--  <th class="price" style="font-size: 12px;width: 10%;">Mrp</th> -->
                        <th class="price" style="font-size: 12px;width: 10%;">Rate</th>
                       <th class="price" style="font-size: 12px;width: 10%;">Net Amt</th> 
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of ds.i_m.items; let k=index;">
                        <td class="price" style="font-size: 14px;">{{k+1}}</td>
                        <td class="description"  style="font-size: 14px;width: 37%;font-weight: bold;">{{item.NAME}}</td>




                        <td *ngIf="item.UNIT !='kg'" class="price"  style="font-size: 14px;width: 7%;">{{item.QTY | number : '1.2-2'}}</td>
                        <td *ngIf="item.UNIT !='kg'" class="price"  style="font-size: 14px;width: 8%;">{{item.UNIT}}</td> 

                         <td *ngIf="item.QTY>=1 && item.UNIT=='kg'" class="price"  style="font-size: 14px;width: 7%;">{{item.QTY | number : '1.2-2'}}</td>
                          <td *ngIf="item.QTY>=1 && item.UNIT=='kg'" class="price"  style="font-size: 14px;width: 8%;">{{item.UNIT}}</td> 
 
                           
                          <td *ngIf="item.QTY<1 && item.UNIT=='kg'" class="price"  style="font-size: 14px;width: 7%;">{{item.QTY * 1000 | number : '1.2-2'}}</td>
                          <td *ngIf="item.QTY<1 && item.UNIT=='kg'" class="price"  style="font-size: 14px;width: 8%;">gm</td> 



                        <!-- <td class="price"  style="font-size: 14px;width: 10%;">{{item.MRP | number : '1.2-2'}}</td> -->
                        <td class="price"  style="font-size: 14px;width: 10%;">{{item.PRICE | number : '1.2-2'}}</td>
                         <td class="price"  style="font-size: 14px;width: 12%;">{{item.NET_PRICE | number : '1.2-2'}}</td> 
                    </tr>

                    <tr style="margin-top: 3px;height: 15px;border-top:1px solid; ">
                      <td>  </td>
                      <td style="font-weight: bold;"> &nbsp; &nbsp; Total </td>
                      <td> {{ this.ds.total_qty | number : '1.2-2'}} </td>
                      <!-- <td> {{this.ds.mrp_total | number : '1.2-2'}} </td> -->
                      <td> </td>
                      <td>  </td>
                      <td> {{this.ds.price_total | number : '1.2-2'}} </td>
                    </tr>
                    
                </tbody>
            </table>

            <!-- <div style="width: 100%;height: 2px;border-bottom: 1px solid black;"> </div>
            <label style="text-align: center;float: top;
            height: 12px;font-weight: bold;line-height: 6px;"> Tax details (GST) </label>
                       
              <div style="width: 100%;height: 2px;border-bottom: 1px solid black;"> </div>

             <table>
              <thead>
                    <tr> <th> Taxable </th> <th> cgst </th> <th> sgst </th> <th> kf.cess </th>
                    </tr>
               </thead>
               <tr>
                <td> 25 </td> <td>251 </td> <td> 258 </td> <td> 259 </td>
               </tr>

             </table> -->

            <div style="width: 100%;height: 2px;border-bottom: 1px solid black;"></div>
           <label style="text-align: center;height: 12px;font-weight: bold;"> Summary </label> 
            <div style="width: 100%;height: 2px;border-bottom: 1px solid black;"></div>
            <table>
            
                <!-- <tr style="border: none;"> <td> Mrp Total : </td> <td> {{this.ds.mrp_total | number : '1.2-2'}} </td> </tr> -->
                   <tr>  <td> Net Total :  </td> <td> {{this.ds.price_total | number : '1.2-2'}} </td> </tr>
                 
                 <tr> <td> Round -off  : </td> <td> {{this.ds.frac | number : '1.2-2'}} </td> </tr>
                <tr style="border-top: 1px dotted; border-bottom: 1px dotted; font-size: 19px;font-weight: bold;height: 21px; ">
                    <td> Total Payable  : </td> <td> {{this.ds.i_m.tails.GRAND_TOTAL | number : '1.2-2'}}  </td> </tr>

                <!-- <tr style="border-top: 1px solid; border-bottom: 1px solid; font-size: 16px;
                font-weight: bold;height: 21px; "> 
                 <td> &nbsp; &nbsp;&nbsp; ## You Saved  </td>
                   <td> &nbsp; &nbsp;&nbsp;   Rs. {{this.ds.i_m.tails.YOUSAVE_TOTAL | number : '1.2-2'}}/-  ## </td>   </tr> -->


                   <tr style="border-top: 1px solid; border-bottom: 1px solid; font-size: 16px;
                   font-weight: bold;height: 21px; "> 
                    <td> &nbsp; &nbsp;&nbsp; Paid : {{this.ds.i_m.tails.PAYED_TODAY | number : '1.2-2'}}  </td>
                      <td> &nbsp; &nbsp;&nbsp;   Bal : {{this.ds.i_m.tails.BAL_PAY  | number : '1.2-2'}} </td>   </tr>



                   
                 
                 <tr style="border-top: 1px solid; border-bottom: 1px solid; font-size: 14px;
                 font-weight: bold;height: 21px; "> 
                  <td> &nbsp; &nbsp;&nbsp; ****  Thank You  </td>
                    <td> &nbsp; &nbsp;&nbsp;  Visit Again  **** </td>   </tr>
              
            </table>


</section>



<!--   End of  80 mm  -->




