export class items {
    constructor(
      
           public si : number ,
           public item : sublist[],
          
         
        
      
    ) {  }
  }
  
  
  export class sublist 
  {
   constructor(
  public SI : number,
  public CUS_NAME : string,
  public ENTRY_DATE : string,
  public ENTRY_NO : number,
  public GRAND_TOTAL: number,
  public PAYED_AMT: number,
  public DUE_AMT: number,

 
  
    ) {  }
  }
  