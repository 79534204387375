import { Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import { DataService } from '../../services/data.service';
import { ShortcutInput, ShortcutEventOutput,KeyboardShortcutsComponent  } from "ng-keyboard-shortcuts";
import { required, requiredTrue,min, maxLength,isAlphabetical,isInteger} from 'ng-easy-validation';
import { Router } from '@angular/router';
import { pdt_model } from './model';
@Component({
  selector: 'app-new-purchase',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class PurchaseComponent implements OnInit {


  myModal_3 : any;
  myModal_4 : any; 
  isPrint :boolean = true;
  Item_name : string;
  Item_id : number = 0;
  Item_hsn_code :  string = null;
  Item_mrp_value : number = 0;
  Item_qty : number = 0;
  Item_tax : number = 0;
  Item_net_price : number = 0;
  Item_price : number = 0;
  Item_stock : number = 0;
  Item_unit : string = '';
  Item_code : string = null;
  id_array : number[] = [];
  loop_var : number = 0;
  on_edit : boolean = false; 
  bill_round_off : number = 0;
  _d : number = 0;
  arr_ : number = 0;
  floor : number;
  frac : number;
  pro_id : number = 0;
  pro_catgery : string ;
  pro_hsn : string;
  pro_unit : string;
  pro_mrp : number;
  pro_tax : number;
  pro_np  : number;
  pro_cat : string;
  pro_qty : number = 1;
  pro_barcode : string;
  pro_price : number;
  pro_name : string;
  pro_free_qty : number;
  pro_name_model : string;
  b : number = 0;
  pro_p_model : number;
  pro_qty_model : number;
  nun : number;
  nux : number;
  isClickedOnce_on_Save : boolean = false;
  arrayOfKeyValues :  any [] =[];
  ia : number = 0;
  ka : number = 0;
  push_disabled : boolean = false;
  push_disabled_enter : boolean = false;
  product_array : pdt_model[] =[];
  shortcuts: ShortcutInput[] = [];
  requirements: any = {

    "Item_name": [
      { validator: required(), tooltipsOnInit: false }
    ],
   
    "Item_qty": [
      { validator: required(), tooltipsOnInit: true },
      { validator: min(0), tooltipsOnInit: true },
      { validator: isInteger(), tooltipsOnInit: true }
    ],
    "Item_net_price" : [
      { validator: required(), tooltipsOnInit: true },
      { validator: min(0), tooltipsOnInit: true },
      { validator: isInteger(), tooltipsOnInit: true }
    ]

  }

  tippyProps: any = {
    animation: 'fade',
    arrow: false,
    placement: 'bottom',
    role: 'tooltip',
    theme: 'translucent',
    zIndex: 9999,
  };

  @ViewChild("autos") _en: ElementRef; 
  @ViewChild('input') input: ElementRef; 
  @ViewChild("item_qty_model") _eq: ElementRef; 
  @ViewChild("item_qty_model_e") _eq_e: ElementRef; 
  @ViewChild("bill_round") _eq_b_r: ElementRef; 
  
  arrayOfproductValues : any = []




  constructor(public ds : DataService, private rs: Router ) {

    
   }  
 

 
   ngOnInit(): void {
                      this.reset_items();
                      this.get_products();
                      this.myModal_3 = document.getElementById("myModal_3");
                      this.myModal_4 = document.getElementById("myModal_4");
                      this.ds.i_v.i = 0;
                      this.ds.i_m.tails.BAL_PAY = 0;
                      this.ds.i_v.k = 0;
                      this.shortcuts.push(
                        {
                          key: ["f2"],
                          label: "Purchase Entry",
                          description: "F2",
                          command: e => this.open_modal_2(),
                          preventDefault: true
                        },
                        {
                          key: ["f8"],
                          label: "Save Purchase",
                          description: "F8",
                          command: e => this.save_Purchase(),
                          preventDefault: true
                        },
                      
                        {
                          key: ["shift + f2"],
                          label: "Close Purchase Menu",
                          description: "shift + F2",
                          command: e =>this.close_modal(),
                          preventDefault: true
                        },


                        {
                          key: ["f4"],
                          label: "Add New Product",
                          description: "F4",
                          command: (output: ShortcutEventOutput) =>
                          this.open_modal_4(),
                            
                        },

                        {
                          key: ["f11"],
                          label: "Full screen",
                          description: "F11",
                          command: (output: ShortcutEventOutput) =>
                         this.open_nothing(),
                            
                        },
                     
                      
                        {
                          key: ["shift + f4"],
                          label: "Close Add product menu",
                          description: "Shift + F4",
                          command: e =>this.close_modal_4() ,
                         
                          preventDefault: true
                        },
                      );


                      this.get_all_products()
                    }

                    reset_items()
                    {
                
                
                        this.ds.i_m.heads.CUS_NAME = 'Cash';
                        this.ds.i_m.tails.GRAND_TOTAL = 0;
                        this.ds.i_m.tails.MRP_TOTAL = 0;
                        this.ds.i_m.tails.YOUSAVE_TOTAL = 0;
                        this.ds.i_m.tails.PAYED_TODAY = null;
                        this.ds.i_m.tails.BAL_PAY = 0;
                      
                        for(this.b=this.ds.i_m.items.length;this.b--;)
                        {
                          this.ds.i_m.items.pop();
                        }
                        
                      
                    }
                    change_item_name(i_n:any)
                    {
                       
                        
                        let bi : any;

                        
                     
                      bi = this.product_array.filter(xi=> xi.BAR_CODE  === i_n);
                     
              
                     
                          if(bi.length == 1)
                          {
                     
                           this.pro_id = bi[0].ID;
                           this.pro_catgery = bi[0].CATGERY;
                          
                           this.pro_unit =  bi[0].UNIT;
                        
                           
                           this.pro_np =  bi[0].PRICE;
                           this.pro_name = bi[0].DESCRIPTION;
                           this.pro_barcode = i_n;
                           this.pro_price = bi[0].PRICE
                           this.pro_cat = bi[0].CATGERY;
                     
                           this._eq.nativeElement.focus(); 
                             this.check_for_duplication(bi[0].ID)
                     
                           
                           this.pro_qty = 1;
                        
                           this.push_disabled = false;
                     
                          }
                        }


 get_products()
 {
  this.ds.get_all_products()
  .subscribe((jsonData) => { this._get_products(jsonData)
          },(err) => console.error(err),
           
          );
 }

_get_products(json :any)
{               


this.arrayOfKeyValues = json;   

this.product_array = json;


}




 pushItem_down()
{
  
    this.stack_push();
    this.ds.i_m.items[this.ia].SI_NO = this.ia+1;
    this.ds.i_m.items[this.ia].NAME = this.pro_name;
    this.ds.i_m.items[this.ia].UNIT = this.pro_unit;

    this.ds.i_m.items[this.ia].QTY = this.pro_qty;
    this.ds.i_m.items[this.ia].PRICE = this.pro_price;
    this.ds.i_m.items[this.ia].NET_PRICE = this.pro_price * this.pro_qty;
    this.ds.i_m.items[this.ia].PRO_ID = this.pro_id;
    this.ds.i_m.items[this.ia].BAR_CODE = this.pro_barcode;


    this.ia = this.ia+1;   
    this._en.nativeElement.value = null 
    this._en.nativeElement.focus(); 
    this.push_disabled = true;
    this.calculation();
}
pushItem()
{
    this.stack_push();
    this.ds.i_m.items[this.ia].SI_NO = this.ia+1;
    this.ds.i_m.items[this.ia].NAME = this.pro_name;
    this.ds.i_m.items[this.ia].UNIT = this.pro_unit;
    this.ds.i_m.items[this.ia].PRO_ID = this.pro_id;
    this.ds.i_m.items[this.ia].BAR_CODE = this.pro_barcode;
    this.ds.i_m.items[this.ia].QTY = this.pro_qty;
    this.ds.i_m.items[this.ia].PRICE = this.pro_price;
    this.ds.i_m.items[this.ia].NET_PRICE = this.pro_price * this.pro_qty;


   
 
    this.ia = this.ia+1;
    this.push_disabled = true;
  //  this._ed.nativeElement.focus(); 
     this.calculation();

    

}
stack_push()
{

  this.ds.i_m.items.push({ SI_NO:this.ia+1,PRO_ID:0, NAME:'',QTY:1,MRP:0,
  PRICE:0,UNIT:'pc',BAR_CODE:'',NET_PRICE:0,ENTRY_NO:0})
  
}         

    save_Purchase()
    {


      this.push_disabled_enter = true;
    
     

      this.ds.post_purchase(this.ds.i_m)
      .subscribe((jsonData) => { this.get_res(jsonData)
                      },(err) => console.error(err),
                     
                      );

       
    }       
    
    get_res(json:any)
    {
      
     
      if(json.success == true)
      {
        alert(json.msg);
  
        this.rs.navigate(['/home']);
      }
      else
      {
        alert(json.msg);
  
        this.rs.navigate(['/home']);
      }
    }


    open_nothing()
    {

    }

    get_all_products()
    {
      this.ds.get_all_products()
      .subscribe((jsonData) => { this.get_products_list(jsonData)
                      },(err) => console.error(err),
                     
                      );
    }

    get_products_list(json:any)
    {
      this.arrayOfproductValues = json;
    }
    
     
   
      focus_item()
      {

       

      

      }

      


  


      change_in_net_price_direct(np:number)
      {
       
         
        this._d = np * 100 / (100 + this.Item_tax); 
        
         this._d =  Math.round(this._d * 10000) / 10000;
       
        this.Item_price = this._d;

      }


      
    


      calculation()
      {
        this.ds.i_m.tails.SUB_TOTAL = 0;
           

            for(this.loop_var = 0;this.loop_var<this.ia;this.loop_var++)
            {
             
              this.ds.i_m.tails.SUB_TOTAL = this.ds.i_m.tails.SUB_TOTAL + (this.ds.i_m.items[this.loop_var].NET_PRICE);
               

               
             
            }

         //   this.ds.i_m.tails.GST_TOTAL =  Math.round( this.ds.i_m.tails.GST_TOTAL * 10000) / 10000;
          


       this.ds.i_m.tails.GRAND_TOTAL =  (this.ds.i_m.tails.SUB_TOTAL-0) -  (this.bill_round_off-0) 

       console.log('res',this.ds.i_m.tails.GRAND_TOTAL)

        this.ds.i_m.tails.YOUSAVE_TOTAL = this.bill_round_off;


      

    
     //  this.ds.i_m.tails.GRAND_TOTAL = this.ds.floor;
      
      
          


      }

      move_to_round()
      {
          this._eq_b_r.nativeElement.focus();
      }
      change_in_ro()
      {
        this.calculation();
      }

      snak_fun(msg:any)
      {
         alert(msg);
      }
      item_remove_flag()
      {

      }

      RemoveItem(index:any)
      {
       
        
         this.arrange_items(index)
      }



      arrange_items(index:number)
      {

      for(this.arr_ = index ; this.arr_ < this.ia-1 ; this.arr_ ++)
      {
        
       

       this.ds.i_m.items[this.arr_].NAME = this.ds.i_m.items[this.arr_+1].NAME;
       this.ds.i_m.items[this.arr_].PRO_ID = this.ds.i_m.items[this.arr_+1].PRO_ID;
       this.ds.i_m.items[this.arr_].QTY = this.ds.i_m.items[this.arr_+1].QTY;
       this.ds.i_m.items[this.arr_].PRICE = this.ds.i_m.items[this.arr_+1].PRICE;       
       this.ds.i_m.items[this.arr_].NET_PRICE = this.ds.i_m.items[this.arr_+1].NET_PRICE;

       this.ds.i_m.items[this.arr_].PRO_ID = this.ds.i_m.items[this.arr_+1].PRO_ID;
       this.ds.i_m.items[this.arr_].BAR_CODE = this.ds.i_m.items[this.arr_+1].BAR_CODE;


     //  this.ds.i_m.items[this.arr_].SI_NO = this.ds.i_m.items[this.arr_+1].SI_NO;


      this.id_array[this.arr_] = this.id_array[this.arr_+1];

      }

     
      this.id_array.pop();
      this.ds.i_m.items.pop();
      this.ia =  this.ia - 1;

    }



      editItem(index:any)
      {
        this.on_edit = true;
        this.ka = index;

        this.pro_name_model = this.ds.i_m.items[index].NAME;
        this.pro_qty_model = this.ds.i_m.items[index].QTY;
        this.pro_p_model = this.ds.i_m.items[index].PRICE;

    

        this.myModal_3.style.display = "block";

        this._eq_e.nativeElement.focus(); 



      
        
      }

        check_for_duplication(id : number)
        {
          
          if(this.ia === 0)
          {
           this.id_array[this.ia] = id;
          }
          else
          {
            this.nux = this.id_array.indexOf(id,0)      
            if(this.nux < 0)
            {          
            this.id_array[this.ia] = id;
            }
            else
            {
              this.nun = this.nux + 1;     
               if (this.nux === this.ia)
                  {    }
            else
            {
               this.snak_fun('Already Present in Position '+ this.nun); 

               this.on_edit = true;
               this.ka = this.nux;
       
               this.pro_name_model = this.ds.i_m.items[this.ka].NAME;
               this.pro_qty_model = this.ds.i_m.items[this.ka].QTY;
               this.pro_p_model = this.ds.i_m.items[this.ka].PRICE;
       
           
       
               this.myModal_3.style.display = "block";
               this._eq_e.nativeElement.focus();
           
              
            }
            }


          }  
        }


         

change_discount(dis_amt:number)
{
  //this.ds.i_m.tails.DISCOUNT_AMT = dis_amt;
  this.calculation();
}

        get_figure(x:any)
              {
                  
              //  this.ds.fig_model.number_to_convert = x;
               //  this.ds.get_figure(this.ds.fig_model)
               //  .subscribe((jsonData) => { this.getjson87(jsonData)
                        //            },(err) => console.error(err),
                                     
                       //             );

              }

      getjson87 (p:any)
      {
       // this.ds.fig_main = p.msg;   
       
      }



     

  

        open_modal_2()
        {
          this.myModal_3.style.display = "block";
          // this._el.nativeElement.focus();
  
        }
        open_modal_4()
        {
          this.myModal_4.style.display = "block";
        }
        close_modal()
        {
         
          this.ds.i_m.items[this.ka].QTY = this.pro_qty_model;
          this.ds.i_m.items[this.ka].PRICE = this.pro_p_model;
          this.ds.i_m.items[this.ka].NET_PRICE = this.pro_p_model * this.pro_qty_model;
          this.calculation();
          this.myModal_3.style.display = "none";
          this._en.nativeElement.value = null;
          this._en.nativeElement.focus();
        }
        close_modal_4()
        {
          this.get_all_products()
          this.myModal_4.style.display = "none";
        }

      @ViewChild(KeyboardShortcutsComponent) private keyboard: KeyboardShortcutsComponent;  

}

