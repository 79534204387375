import { Component, OnInit,Renderer2, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../services/data.service';

import {FormBuilder, FormGroup,FormControl, Validators} from '@angular/forms';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';




@Component({
  selector: 'app-report-main',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class ReportmainComponent implements OnInit {

    myOptions: IAngularMyDpOptions = {
        dateRange: false,
        dateFormat: 'dd.mm.yyyy'
        // other options...
      };
    
      public myForm: FormGroup;

  constructor(private ds : DataService,private fb: FormBuilder,
     private renderer: Renderer2, private cdr: ChangeDetectorRef  ) {

    
   }  
 

 

  ngOnInit(): void {

   

       
    let model: IMyDateModel = {isRange: false, singleDate: {jsDate: new Date()}, dateRange: null};
    this.myForm = this.fb.group({
      myDate: [model, Validators.required],
      myDateto: [model, Validators.required]
     
    });

    this.clearDate();
    this.clearDate_2();

  }

  setDate(): void {
    // Set today date using the patchValue function
    let model: IMyDateModel = {isRange: false, singleDate: {jsDate: new Date()}, dateRange: null};
    this.myForm.patchValue({myDate: model});
  }

  clearDate(): void {
    // Clear the date using the patchValue function
    this.myForm.patchValue({myDate: null});
  }

  
  setDate_2(): void {
    // Set today date using the patchValue function
    let model: IMyDateModel = {isRange: false, singleDate: {jsDate: new Date()}, dateRange: null};
    this.myForm.patchValue({myDateto: model});
  }

  clearDate_2(): void {
    // Clear the date using the patchValue function
    this.myForm.patchValue({myDateto: null});
  }
   

  console()
  {
    console.log('val',this.myForm.value);
    // this.ds.report_demo.from_date = this.myForm.value.myDate.singleDate.jsDate;
    // this.ds.report_demo.to_date = this.myForm.value.myDateto.singleDate.jsDate;
      console.log('dm',this.ds.report_demo.from_date )
      console.log('dm', this.ds.report_demo.to_date )
  }

  onDateChanged(event: IMyDateModel): void {

    console.log('onDateChanged(): ', event);
   // this.ds.report_demo.from_date = this.myForm.value.myDate.singleDate.jsDate;
   this.ds.report_demo.from_date =  event.singleDate.jsDate;
  }
  onDateChanged_2(event: IMyDateModel): void {

    console.log('onDateChanged(): ', event);
    this.ds.report_demo.to_date =  event.singleDate.jsDate;
    // this.ds.report_demo.to_date = this.myForm.value.myDateto.singleDate.jsDate;
  }
   
  }



  







  



