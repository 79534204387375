





<nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#/home">Home</a></li>
          <li class="breadcrumb-item"><a href="#/home">Report</a></li>
          <li class="breadcrumb-item active" aria-current="page">Collection Report</li>
        </ol>
      </nav>
      
    
        
        
        
        
        
        
         
        
        
         
        
        
        
        
        <section *ngIf="view" style="width: 100%;height: 30px;">
          
        <div style=" width: 100%;
            height: 10px;
            font-size: x-large;
            text-align: center;"> Sales Report </div>
        
        <div style="width: 100%;
            height: 10px;">
        <label style="width: 100%;height: 10px;">
          
          Date from : {{this.ds.report_demo.from_date | date:'short' }}   Date to : {{this.ds.report_demo.to_date | date:'short'}}
        </label>  
        
        
         
        
         </div>
        
        </section>
        
        
        
        <section *ngIf="view" style="width: 100%;
            margin-top: 30px;background-color: plum;">
        
          <div class="table_card">
        
        
        
            <div class="col-xs-12">
              <div class="table-responsive my_t" data-pattern="priority-columns">
                <table id="Sales_Table_cat" class="table table-bordered table-hover">
                  
                  <thead class="t_h">
                    <tr>
                      <th>SI</th>
                                 
                      <th data-priority="2">Name</th>
                      <th data-priority="3"> Entry No </th>
                       <th data-priority="3"> Entry Date </th>
                       <th data-priority="3"> Grand Total </th>
                       <th data-priority="3"> Total Payed </th>
                       <th data-priority="3"> Total Due </th>
              
                    </tr>
                  </thead>
                  <tbody >
                    <tr *ngFor = "let item of post_invo.item ">

                      <td>{{item.SI}}</td>                 
                      <td>{{item.CUS_NAME }}</td>
                      <td>{{item.ENTRY_NO}}</td>
                      <td>{{item.ENTRY_DATE}}</td>
                      <td>{{item.GRAND_TOTAL}}</td>
                      <td>{{item.PAYED_AMT}}</td>
                      <td>{{item.DUE_AMT}}</td>
                     
                      
                    </tr>
                   
                   
                  </tbody>
                  <tfoot>
                   
                  </tfoot>
                </table>
              </div>
            </div>
        
        
          
          
        
        </div>  
          
        
        </section>
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        