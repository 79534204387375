<app-purchase-head> </app-purchase-head>


<section id="non-printable"  style="width: 100%;height: 50px;">

    <div class="table-responsive-sm">
  
    <table style="width:100%;font-family: monospace;color:#eae4e4;             
    font-size: 11pt;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    background-color: #545454;
    border-collapse: collapse;">
       <tr>
          
           <th style="width:15%;">Product Code</th>  
           <th style="width:15%;">Item Name</th> 
           <th style="width:5%;">Qty</th>       
           <th style="width:10%;">Price</th>   
  
            <th> + </th>                 
  
  
         </tr>
  
         <tr style="color:snow;background-color: #a6d5d8;">
  
           
  
         <td class="nguui" height="10" style="width:15%;color: #545454;"> 
           <input ngui-auto-complete #autos type="text" name="autos" style="width: 90%; 
           height: 25px;
           border-color: #52c3bb;
           display: inline-block;
           background-color: unset;
           "class="form-control"  [source]="arrayOfKeyValues"  
        placeholder="barcode"      
         (keyup)="change_item_name(autos.value)" 
         (keyup.esc)="move_to_round()"
         list-formatter=" BAR_CODE -- DESCRIPTION"
         loading-text = "loading"
         display-property-name="BAR_CODE"
          [(ngModel)]="pro_barcode"> </td>
  
  
  
  
          <td height="10" style="width:18%;">   
           <input #item_name_model [disabled]="true"  style="text-align:center;border-radius: 6px;width: 260px;
           background-color: unset;border-color: #52c3bb;color: black;"  type="text" placeholder="Item name" 
        [(ngModel)]="pro_name">   </td>
  
  
          <td height="10" style="width:8%;">   
           <input #item_qty_model style="text-align:center;border-radius: 6px;width: 95px;
           background-color: unset;border-color: #52c3bb;color: black;" (keyup.enter)="pushItem_down()" 
            type="number" placeholder="Quantity" 
         [(ngModel)]="pro_qty">   </td>
  
         
   
               
         
          <td height="10" style="width:12%;">  
              <input type="number" style="width: 140px; text-align:center;font-family: cursive; 
              background-color: unset;border-color: #52c3bb;
           border-radius: 6px;" #box_net (keyup.enter)="pushItem_down()"  [(ngModel)]="pro_price"                     
            
           size="10" placeholder=" " >  
         
         </td>
  
  
  <td class="td_button" height="10" style="width:12%;"> 
  
  <button [disabled]="push_disabled"  (click)="pushItem()" (keydown.ArrowDown)="pushItem_down()"  type="button" class="self">
            Add
          </button> 
  
  
  
  </td>
  
  
         </tr>
  
  
  
  </table>
  </div>
  
  </section>
  

  <section *ngIf="this.ia>0" id="non-printable" style="background-color: #656565;color: #fffc9e;height: auto;width:100%;">

    
      <table   style="width:100%;font-family: monospace;color:#eae4e4;             
      font-size: 10pt;
      font-style: normal;
      font-weight: bold;
      text-align: center;
      border-collapse: collapse;">
         <tr style="color:#c4f37a;" >
             <th>SI</th>
             <th>Description</th>            
             <th>Quantity</th>
             <th>Unit</th>            
             <th>price</th>
             <th>Net value</th>
             <th> Edit  </th>
             <th> Delete </th>
 
 
           </tr>
         <tr *ngFor="let item of ds.i_m.items; let k=index " height="10">
           <td height="15" style="width:5%;">   {{item.SI_NO}} </td>
 
           <td> {{item.NAME}} </td>
           <td> {{item.QTY}} </td>
           <td> {{item.UNIT}}    </td>          
           <td> {{item.PRICE  | number : '1.2-4' }} </td>
           <td> {{item.NET_PRICE | number : '1.2-2'}} </td>    
 
           <td height="8" style="width:5%;" class="tds_plus">  
               <button (click)="editItem(k)" (keydown.ArrowDown)="editItem(k)"
            class="btn-outline-dark" style="background-color:violet"> 
                <span class="navbar-toggler-icon"> 
               <img src="../../assets/icons/edit.svg" style="width: 15px;
             height: 15px;" /> </span>
             </button>               
         </td>
           <td height="8" style="width:5%;" class="tds_plus" >  
           <button (click)="RemoveItem(k)" class="btn-outline-dark"  style="background-color:salmon" >  
              <span class="navbar-toggler-icon"> 
               <img src="../../assets/icons/delete_dob.svg" style="width: 15px;
             height: 15px;" /> </span></button>
           </td>        
         </tr>     
       </table>
 
 
       <div  class="footer">
 
 
         <table    style="width:100%;margin-top:10px;">
           <tr style="    background-color: #306737;
           color: gold;">
             <th> Sub Total</th>
            
        
             <th style="width:220px;"> Round- off (-)</th>
             <th> Grand Total</th>
             <th> Save  </th>
 
           </tr>
 
           <tr style="background-color: midnightblue;">
             <td>{{this.ds.i_m.tails.SUB_TOTAL  | number : '1.2-2'}}</td>
       
        
             <td> <input #bill_round type="number" style="width:90%;margin-left: 5%;text-align: center;"
               class="form-control" [(ngModel)]="bill_round_off" (keyup)="change_in_ro()"
              aria-label="Small" aria-describedby="inputGroup-sizing-sm">  
            </td>
             <td>{{this.ds.i_m.tails.GRAND_TOTAL  | number : '1.2-2'}}</td>
             <td>
                 <button  [disabled]="push_disabled_enter"  style="width: 150px;margin-left: 10px;" type="button" 
                 class="btn btn-primary" (click)="save_Purchase()">
              
                     > F8
                    
                  </button>
             </td>
 
 
           </tr>   
 
 
         </table>
 
 
        
       </div>
 
 
 
 </section>
 





<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<ng-keyboard-shortcuts-help [key]="'f1'" [closeKey]="'escape'" [title]="'Help'"></ng-keyboard-shortcuts-help>







<div id="myModal_3" class="modal">

       
  <!-- Modal content -->
  <div class="modal-content">
      <label  class="btn btn-primary" >
         Edit Item
      </label> 

      <div class="row">

          <div class="col-sm-3">

              <div class="input-group input-group-sm mb-6">
                       <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-sm"> Name </span>
                       </div>
                    <input [disabled]="true" type="text" class="form-control" [(ngModel)]="pro_name_model" 
                     aria-label="Small" aria-describedby="inputGroup-sizing-sm">
                  </div>
    
     
            </div>

        
        <div class="col-sm-3">

          <div class="input-group input-group-sm mb-6">
                   <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm"> Qty </span>
                   </div>
                <input #item_qty_model_e type="number" class="form-control" [(ngModel)]="pro_qty_model" 
                 aria-label="Small" aria-describedby="inputGroup-sizing-sm">
              </div>

 
        </div>

        <div class="col-sm-3">

          <div class="input-group input-group-sm mb-6">
                   <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">price</span>
                   </div>
                <input type="number" class="form-control" [(ngModel)]="pro_p_model"  aria-label="Small" aria-describedby="inputGroup-sizing-sm">
              </div>
        </div>
        
</div>   

<br>
     

        <button style="width: 150px;margin-left: 10px;" type="button" class="btn btn-primary" (click)="close_modal()">
          Update & close
        </button>

  </div>


     
      
 </div> 


      <div id="myModal_4" class="modal">
            <div class="modal-content">
                    <label  class="btn btn-primary" >
                        Add New Product
                    </label> 

                    <app-add-product>     
                    </app-add-product>
                    <br>
                    <button style="width: 150px;margin-left: 10px;" type="button" class="btn btn-primary" (click)="close_modal_4()">
                            Close
                     </button>
                
                </div>

        
      </div>




  
<section id="printable" style="width:100%;margin: 0;
font-family: Verdana, Geneva, Tahoma, sans-serif;background-color: red; "> 



Print
  


</section>
    