import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { p_master } from './model'
@Component({
  selector: 'app-update-product',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class Update_productComponent implements OnInit {

 
  pp_master = new p_master(0,[])
  cats :any[] = ['nos'];
  product_add_Form : FormGroup;
  arrayOfproductValues : any[] =[];
  d_ : number = 0;
  view_head : boolean = false;
  item_flag : boolean = false;
  units :  any[] = [];
  productForm : FormGroup;
  constructor(private ds : DataService ,private fb : FormBuilder ) {

    
   }  
 

 

  ngOnInit(): void {

    

      this.get_all_products();
      this.get_all_units();
      this.productForm = this.fb.group({ 

        description : ['', [<any>Validators.required, <any>Validators.minLength(3)]],     
        mrp_price : 0,       
        bar_code : [''],
        unit : [''],
        price:[''],
        net_price:[''],    
        id:0
        
      
      });
    
      
  }

  get_all_units()
  {
      this.ds.get_all_units()
     .subscribe((jsonData) => { this.get_units_list(jsonData)
                     },(err) => console.error(err),
                    
                     );
  }

 
 get_units_list(js:any)
 {
     for(var jsp=0; jsp<js.length;jsp++)
     {
       this.units[jsp] = js[jsp].UNIT_NAME
 
     }
     
 }
      
  
  Update_all()
  {
      

     console.log('val',this.productForm.value);

     this.ds.update_product(this.productForm.value)
     .subscribe((jsonData) => { this.get_ress_update(jsonData)
                     },(err) => console.error(err),
                    
                     );


  }
  get_ress_update(json:any)
  {
    console.log('res',json);
 
       alert(json.msg);
       this.get_all_products();

       this.productForm.reset();
      
   
  }
  article_blur(ss:number)
  {
        let s : any; 

s = this.arrayOfproductValues.filter(xi=> xi.ID == ss);
this.productForm.patchValue({id:ss})

console.log('fl ar',s);

      this.productForm.patchValue({description : s[0].DESCRIPTION , category : s[0].CATGERY ,
        bar_code : s[0].BAR_CODE,
        price : s[0].PRICE, 
                mrp_price : s[0].MRP,
                 unit : s[0].UNIT  });
  }




  get_all_products()
 {
 	  this.ds.get_all_products()
    .subscribe((jsonData) => { this.get_product_list(jsonData)
                    },(err) => console.error(err),
                   
                    );
 }


 get_product_list(js:any)
{
   console.log(js)
   this.arrayOfproductValues = js;
   console.log('pdts',this.arrayOfproductValues)
   this.view_head = true;

  


}

Delete_all()
{

  console.log('val',this.productForm.value);

  this.ds.delete_product(this.productForm.value)
  .subscribe((jsonData) => { this.get_ress_update(jsonData)
                  },(err) => console.error(err),
                 
                  );
    
}

desc_blur(it:any)
{

}

   
 
  



}

