<div>

    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#/home">Home</a></li>
          <li class="breadcrumb-item"><a href="#/home">Report</a></li>
         
        </ol>
      </nav>

      
      
    <form [formGroup]="myForm">

        <div class="container">
            <div class="row">

                    <div class="col-12 col-md-6 col-lg-4">
        <div class="input-group">
      
            <label> Select From Date </label>

            <input class="form-control" placeholder="Select a date" angular-mydatepicker name="myDate"  (dateChanged)="onDateChanged($event)" 
              formControlName="myDate"[options]="myOptions" #dp="angular-mydatepicker"/>
    
              <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp.clearDate()">
                        <img src="../../assets/icons/close.svg" style="width: 15px; height: 15px;" />
                  </button>
                </div> 
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary"  (click)="dp.toggleCalendar()">
                        <img src="../../assets/icons/calendar.svg" style="width: 15px; height: 15px;" /> 
                  </button>
                </div>
        </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
                <div class="input-group">
              
                    <label> Select To Date </label>
        
                    <input class="form-control" placeholder="Select a date" angular-mydatepicker name="myDateto"  (dateChanged)="onDateChanged_2($event)"
                      formControlName="myDateto"[options]="myOptions" #dps="angular-mydatepicker"/>
            
                  
                    <div class="input-group-append">
                        <button type="button" class="btn btn-secondary" (click)="dps.clearDate()">
                              <img src="../../assets/icons/close.svg" style="width: 15px; height: 15px;" />
                        </button>
                      </div> 
                      <div class="input-group-append">
                        <button type="button" class="btn btn-secondary" (click)="dps.toggleCalendar()">
                              <img src="../../assets/icons/calendar.svg" style="width: 15px; height: 15px;" /> 
                        </button>
                      </div>

                </div>
                </div>



    </div>

    </div>
    
      
    </form>



    
<div  class="list-group" style="margin-top:15px;"> 
    <label class="list-group-item active">
     
      <h4 class="list-group-item-heading">Find your Report Type</h4>
      
    </label>
  
  
    <a href="/#/report-sales" class="list-group-item">
      
      <h4 class="list-group-item-heading">Sales Report</h4>
      
    </a>
  
      <a href="/#/collection-report" class="list-group-item">
      
      <h4 class="list-group-item-heading">Cash collection Report</h4>
      
    </a>

    <a href="/#/report-purchase" class="list-group-item">
      
      <h4 class="list-group-item-heading">Purchase Report</h4>
      
    </a>


    </div>


    
  
   

</div>