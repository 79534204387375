import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-print-eighty',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class PrinteightyComponent  {
  
 b : number = 0;
 bc : number = 0;
 ngOnInit() {
 
 
  }
  constructor(private _formBuilder: FormBuilder,
    private router: Router,public ds:DataService ) { }
  
  





customer_statement()
{
  this.router.navigate(['/customer-statement']);
}


}