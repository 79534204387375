



  


<div class="table-responsive" style="margin: 0px;">

    <table  class="table" style="margin:0px;" >
     
     
       <tr > 

        <td style="width: 5%;"> <li class="home_li"><a href="#/home">Home</a></li></td> 

      <td style="width: 180px;">
            <label for="dateofinvoice">Date of Invoice #</label>
        </td>
                 <td style="width: 18%;">  
   
                   
  
                                        <div  class="input_grove">
                                           
  
  
                                                <input  placeholder="{{!myDatePickerOptions.dateRange ?
                                                  'Select a date' : 'Select a date range'}}" angular-mydatepicker name="mydate"
                                                    [(ngModel)]="model_2" [options]="myDatePickerOptions"  [locale]="locale"
                                                     #dp="angular-mydatepicker"
                                                    (dateChanged)="onDateChanged($event)" 
                                                    
                                                    (inputFieldChanged)="onInputFieldChanged($event)"
                                                   [attr.disabled]="disabled?'':null" autocomplete="off"/>
  
                                                   <div class="input-group-append">
                                                          <button type="button" class="btn btn-secondary" (click)="dp.clearDate()">
                                                                <img src="../../assets/icons/close.svg" style="width: 15px; height: 15px;" />
                                                          </button>
                                                        </div> 
                                                        <div class="input-group-append">
                                                          <button type="button" class="btn btn-secondary" (click)="dp.toggleCalendar()">
                                                                <img src="../../assets/icons/calendar.svg" style="width: 15px; height: 15px;" /> 
                                                          </button>
                                                        </div>
                                          
                                         
                                           
                                         </div>
                                 
  
                    
                  </td>     
      




<!--    
      <td style="width:160px;">Invoice Type #</td>
      <td style="width:25%;">
            
    
          <fieldset class="multiswitch">
              
            
              <div class="slide-container">
    
    
    
                 <input [attr.disabled]="true" type="radio"  name="account" id="account1" [checked]="entry_id === 1">
                <label for="account1">B2B</label>
                <input [attr.disabled]="true" type="radio"  name="account" id="account2" [checked]="entry_id === 2">
                <label for="account2">B2C</label>
                <input [attr.disabled]="true" type="radio"  name="account" id="account3" [checked]="entry_id === 3">
                <label for="account3">SEZ</label>
                
                <input [attr.disabled]="true" type="radio"  name="account" id="account4" [checked]="entry_id === 4">
                <label for="account4">IGST</label> 
           
                 <a class="slide" aria-hidden="true"></a> 
              </div>
            </fieldset>
            
       
    
    
     
      </td> -->

      <td style="width: 120px;"> </td>

      <td style="text-align: left;width: 250px;  font-size: x-large;  font-weight: bold;">
        <label>  </label> 
        
      
      </td>
    
    
    
      </tr>  
  

    
     </table>  
    
    </div>
    
    
    
  
    
    <section id="non-printable" style="background-color: #656565;color: #fffc9e;height: 54px;width:100%;">
    
       
<div class="table-responsive" style="margin: 0px;">

  <table  class="table" style="margin:0px;">
     <tr>
  
    <td  style="width: auto;text-align: left;">              
      <label>Buyer Name :  </label>   
   <div style="display:inline;    position: absolute;color: #545454;">   
        <input #bui  type="text" style="width: 98%;
        height: 25px;
        border-color: #52c3bb;
        display: inline-block;
        background-color: unset;
        color: #4af33f;"  class="form-control" 
     placeholder="Customer name"     
      (blur)="cus_blur(bui.value)"  [(ngModel)]="this.ds.i_m.heads.CUS_NAME" [ngModelOptions]="{standalone: true}">
      </div> 
      
   

      </td> 

<!-- 
      <td  style="width: auto;text-align: left;">              
        <label>Buyer addess :  </label>   
     <div style="display:inline;    position: absolute;color: #545454;">   
          <input #bui_a  type="text" style="width: 98%;
          height: 25px;
          border-color: #52c3bb;
          display: inline-block;
          background-color: unset;
          color: #4af33f;"  class="form-control" 
       placeholder="Customer addess"     
        (blur)="cus_blur_address(bui_a.value)"  [(ngModel)]="this.ds.i_m.heads.CUS_ADDRESS" [ngModelOptions]="{standalone: true}">
        </div> 
        
     
  
        </td>  -->
  

      


      
      


    </tr>

    <!-- <tr>
     
      <td  style="width: auto;text-align: left;">              
        <label>Buyer Ph :  </label>   
     <div style="display:inline;    position: absolute;color: #545454;">   
          <input  type="text" style="width: 98%;
          height: 25px;
          border-color: #52c3bb;
          display: inline-block;
          background-color: unset;
          color: #4af33f;"  class="form-control" 
       placeholder="Customer addess"     
        (blur)="cus_blur_ph(bui.value)"  [(ngModel)]="this.ds.i_m.heads.CUS_MOB" [ngModelOptions]="{standalone: true}">
        </div> 
        
     
  
        </td> 

        <td  style="width: auto;text-align: left;">              
          <label>Buyer GstIn :  </label>   
       <div style="display:inline;    position: absolute;color: #545454;">   
            <input  type="text" style="width: 98%;
            height: 25px;
            border-color: #52c3bb;
            display: inline-block;
            background-color: unset;
            color: #4af33f;"  class="form-control" 
         placeholder="Customer gstin"     
          (blur)="cus_blur_gstin(bui.value)"  [(ngModel)]="this.ds.i_m.heads.CUS_GST_NO" [ngModelOptions]="{standalone: true}">
          </div> 
          
       
    
          </td> 



    </tr> -->


    </table>

    </div>

   </section>  



  
  


  
    
    
             
                  
    
         
        
