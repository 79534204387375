







<app-header> </app-header>


 




<section style="width:100; background-color: wheat">
    
<!-- 
<div class="onoffswitch3">
    <input type="checkbox" name="onoffswitch3" class="onoffswitch3-checkbox" id="myonoffswitch3" checked>
    <label class="onoffswitch3-label" for="myonoffswitch3">
        <span class="onoffswitch3-inner">
            <span class="onoffswitch3-active">
                <marquee class="scroll-text"> New Version 4.0.1 Released.....<span class="glyphicon glyphicon-forward"></span> 
                  Contact Support for Updation.. </marquee>
                <span class="onoffswitch3-switch"> Updates  </span>
            </span>
            <span class="onoffswitch3-inactive"><span class="onoffswitch3-switch">Show  Update News</span></span>
        </span>
    </label>
</div>   -->


</section>
        

  


<section style="width:100%; background-color: wheat;">

    <div class="about-us-area about-page section-padding-30-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-12 col-lg-5">
                    <div class="about-content">
                        <h2 class="malayalam"> Horn Bill</h2>
                        <p class="malayalam text-justify text-size">
                            Hornbill 4.0 is the finest Invoicing app that satisfies all the needs for a finanacial Organisation.

                      <br>   <label style="font-weight: bold;"> Version : 4.0 </label> <br>
                         <label style="font-weight: bold;"> Licence : Active </label> <br>
                         <label> User : Accounts </label> <br>
                         <label> Provision : Admin </label> <br>


                           <strong> Be Reportive find resolutions together... </strong>.
                        </p>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="about-thumbnail">
                        
                        
                        
                        <img src="../assets/images/horn_nim.jpg" alt="TEMPLE">
                    </div>
                </div>
            </div>

        </div>
    </div>

</section>









<!-- ##### Subscribe Area End ##### -->


    
        <br style="width: 100%;background-color: wheat">
       <br style="width: 100%;background-color: wheat">
<section class="contact-area" style="width: 100%;background-color: wheat">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="contact-content-area">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div class="contact-content contact-information">
                                    <h4>Contact</h4>
                                    <p><a href="google.com" class="__cf_email__" 
                                        data-cfemail="aacfc6cbc7c1dfc6cbc7d9d8cfcfc7cbc2cbcecfdccbdecfc7dac6cfeacdc7cbc3c684c9c5c7">
                                       Mail to :  [premji@csweb.in]</a></p>
                                    <p>  <a href="https://csweb.in"> Website </a> </p> 
                                    <p class="text-align malayalam"> <b>Support : </b>7012406551 </p>
                                    <p class="text-align malayalam"> <b>Sales : </b> 8129511573 </p>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="contact-content contact-information">
                                    <h4>Address</h4>
                                    <p>1st floor Akshaya E-kendra Perumpally Junction, Valizheekal P.O, Kayamkulam - 690535</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="contact-content contact-information">
                                    <h4 class="malayalam"> Office Time </h4>
                                    
                                    
                                    
                                    

                                    <p><b>Morning </b> 8.30 am - 1.15 pm</p>
                                    <p><b> After noon </b> 4pm - 8.15 pm</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    
       
<section style="width:100%;">
   <app-footer></app-footer>
</section>





