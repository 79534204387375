

<div class="main">
    
    
    <div class="container">

<div class="middle">
      <div id="login">

        <form  [formGroup]="loginForm">

          <fieldset class="clearfix">

            <p> <span class="fa">  <img src="../../assets/icons/user.png" style="width: 25px;" /> </span>

            <input type="text" formControlName="username" Placeholder="Username" required> </p> 


            <p><span class="fa">  <img src="../../assets/icons/password.png" style="width: 28px;" />  </span>


              <input type="password" formControlName="password" Placeholder="Password" required></p> 
            
             <div>
                                <span style="width:48%; text-align:left;  display: inline-block;"><a class="small-text" href="#">Forgot
                                password?</a></span>
                                <span style="width:50%; text-align:right;  display: inline-block;">
                                  
                                    <button style="width:100%;" class="button button1" (click)="login()" >Log In</button> </span>
                                  <!-- <input type="submit" value="Sign In"></span> -->
                            </div>

          </fieldset>
<div class="clearfix"></div>
        </form>

        <div class="clearfix"></div>

      </div> <!-- end login -->





   





      <div class="logo">Core Soft Technology
          
          <div class="clearfix"></div>
      </div>
</div>

    </div>


</div>
