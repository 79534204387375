<nav id="navbar" style="margin-top:50px;">
    <ul>

        <li>
            <a>Product</a>  <i class="fas fa-angle-down"></i>
            <ul>
          
              <li><a (click)="add_pro_dir()">New Product</a></li>
              <li><a href="/#/product_update_page">Update Product</a></li>
              <li><a href="/#/delete-product">Delete Product</a></li>
            </ul>
          </li>


    

                  
      <li>
          <a>Settings</a>  <i class="fas fa-angle-down"></i>
          <ul>
        
            <li><a href="/#/manage-prefix">Manage Prefix</a></li>
            <li><a href="/#/back_up">Backup Data</a></li>
          </ul>
        </li>





      <li><a href="https://csweb.in">Contact</a></li>
    </ul>
      <div class="clr"></div>  
  </nav>




