


import { Component, OnInit,Renderer2, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../services/data.service'
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

import { Router } from '@angular/router';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';


@Component({
  selector: 'app-view-purchases',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class ViewPurchaseallComponent implements OnInit {

 


   public configuration: Config;
  public columns: Columns[];
  view : boolean = false;
  clicked : number = 0;

  public data = [{
    phone: '+1 (934) 551-2224',
    age: 20,
    address: { street: 'North street', number: 12 },
    company: 'ZILLANET',
    name: 'Valentine Webb',
    isActive: false,
  }, {
    phone: '+1 (948) 460-3627',
    age: 31,
    address: { street: 'South street', number: 12 },
    company: 'KNOWLYSIS',
    name: 'Heidi Duncan',
    isActive: true,
  }];





  constructor(private ds : DataService,private formBuilder: FormBuilder, private rs: Router,
     private renderer: Renderer2, private cdr: ChangeDetectorRef  ) {

    
   }  
 

 
   ngOnInit(): void {



    this.get_bills();


     this.configuration = { ...DefaultConfig };
    this.configuration.searchEnabled = true;
    // ... etc.
    this.columns = [
      { key: 'CUS_NAME', title: 'Name' },
      { key: 'CUS_ADDRESS', title: 'Address' },
      { key: 'ENTRY_NO', title: 'Bill No' },
      { key: 'ENTRY_DATE', title: 'Date' },
      { key: 'GRAND_TOTAL', title: 'Amount' },
    
     
    ];
  

  }






  get_bills()
  {
    this.ds.get_all_purchases()
    .subscribe((jsonData) => { this._get_bills(jsonData)
            },(err) => console.error(err),
             
            );
  }
 


_get_bills(json :any)
{               



console.log('bills',json)

this.data = json;
this.view = true;
          
   



}


eventEmitted($event) {

  console.log('event',$event)

  if($event.event == 'onSearch' || $event.event == 'onPagination')
  {
      
  }
  else
  {
            this.clicked = $event.value.row.ENTRY_NO;
            alert("Selected Invoice Number  " + this.clicked);   
            this.ds.i_m.heads.INVOICE_NUMBER = this.clicked
             this.rs.navigate(['/purchase-update']);

  }


}




  

}

