import { Component, OnInit } from '@angular/core';
import { AuthGuard } from '../services/auth.guard';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.html',
  styleUrls: ['./header.css']
})
export class HeaderComponent implements OnInit {


 
  constructor(private Ag: AuthGuard,private rs :Router,private ds : DataService) { }

 ngOnInit(): void {

  }
  Log_out()
  {
   this.Ag.token = false;
   this.rs.navigate(['/']);
  }
  back_up()
  {

    this.ds.get_back_up('cap')
    .subscribe((jsonData) => { this._get_ts(jsonData)
            },(err) => console.error(err),
             
            );
    
  }
  _get_ts(js:any)
  {
    alert(js);
  }


}

