
import { Component, OnInit,ViewChild,ChangeDetectorRef,Renderer2 } from '@angular/core';
import { DataService } from '../../services/data.service';
import {AngularMyDatePickerDirective, DefaultView, IAngularMyDpOptions,IMyInputFieldChanged, 
   IMyDateModel, IMyMarkedDate, CalAnimation} from  'angular-mydatepicker';

@Component({
  selector: 'app-purchase-head',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})
export class PurchaseheadComponent implements OnInit {
  
 // arrayOfCusValues : any[] =[];
  cus_vend_model : any = null;
  modal : any;
  modal_2 : any;

  public myDatePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    firstDayOfWeek: 'mo',
    sunHighlight: true,
    markCurrentDay: true,
    alignSelectorRight: false,
    openSelectorTopOfInput: false,
    minYear: 2019,
    maxYear: 2200,
    showSelectorArrow: true,
    monthSelector: true,
    yearSelector: true,
    satHighlight: false,
    highlightDates: [],
    disableDates: [],
    disableHeaderButtons: true,
    showWeekNumbers: false,
    disableDateRanges: [
      {begin: {year: 2016, month: 10, day: 5}, end: {year: 2016, month: 10, day: 7}},
      {begin: {year: 2016, month: 10, day: 10}, end: {year: 2016, month: 10, day: 12}}
    ],
    disableUntil: {year: 0, month: 0, day: 0},
    disableSince: {year: 2022, month: 2, day: 2},
    disableWeekdays: [],
    markDates: [],
    markWeekends: <IMyMarkedDate>{},
    selectorHeight: '200px',
    selectorWidth: '220px',
    closeSelectorOnDateSelect: true,
    closeSelectorOnDocumentClick: true,
    showMonthNumber: true,
    appendSelectorToBody: false,
    focusInputOnDateSelect: true,
    dateRangeDatesDelimiter: " - ",
    defaultView: DefaultView.Date,
    showFooterToday: false,
    calendarAnimation: {in: CalAnimation.ScaleCenter, out: CalAnimation.Fade},
    rtl: false,
    stylesData:
      {
        selector: '',
        styles: ''
      }
  };

  public selectedDate: any;
  public disabled: boolean = true;
  public entry_id : number = 2;
  public model_date: IMyDateModel = null; 
  public inputText: string = "";
  public validDate: boolean = false;
 


  @ViewChild('dp') ngxdp: AngularMyDatePickerDirective;

    

  public selectorSizes: Array<string> = new Array('266px x 266px', '200px x 220px', '260px x 290px');
  public defaultViews: Array<string> = new Array('date', 'month', 'year');
  public calAnimations: Array<string> = new Array('None', 'Fade', 'ScaleTop-ScaleCenter', 'ScaleCenter-ScaleTop', 'Rotate', 'FlipDiagonal');
  public styleColor: Array<string> = new Array('Default', 'Grey', 'Blue', 'Green', 'Red', 'Yellow', 'Dark');

  public locale: string = 'en';
  

  

  constructor(public ds : DataService ,private cdr: ChangeDetectorRef ,
    private renderer: Renderer2 ) {

    
   }  
 

 

  ngOnInit(): void {

    this.modal = document.getElementById("myModal");
    this.modal_2 = document.getElementById("myModal_2");
    this.model_date = {isRange: false, singleDate: {jsDate: new Date()}};
    this.ds.i_m.heads.INVOICE_DATE = new Date();

  //  this.get_customers();
   
  
  }

  // get_customers()
  // { 
   

  //    this.ds.get_customers_list()
  //   .subscribe((jsonData) => { this.get_customer_res(jsonData)
  //                       },(err) => console.error(err),
                         
  //                       );
  // }

  // get_customer_res(json:any)
  // {
  //   console.log('res',json);
  //   this.arrayOfCusValues = json.filter(xy=>xy.TYPE_ == 1)
  //   console.log('result',this.arrayOfCusValues);
  // }

  open_modal()
  {
    
    this.modal.style.display = "block";
  }
  close_modal()
 {
  this.modal.style.display = "none";
 }

 update_close_modal()
 {
   this.modal.style.display = "none";
 }

 open_modal_bill_dets()
 {   
   this.modal_2.style.display = "block";
 }
  


  focus_cus()
{
  this.entry_id = 2;
  // this.ss.i_m.heads[0].CUS_ID =  0
  // this.ss.i_m.flags[0].IS_LISTED_CUSTOMER = false;
  // this.ss.i_m.heads[0].CUSTOMER_NAME =  null ;
  // this.ss.i_m.heads[0].CUSTOMER_STREET = null;
  // this.ss.i_m.heads[0].CUSTOMER_CITY  = null;
  // this.ss.i_m.heads[0].CUSTOMER_PHONE = null;
  // this.ss.i_m.heads[0].CUSTOMER_PHONE2 = null;
  // this.ss.i_m.heads[0].CUSTOMER_GST_IN = null;
  // this.ss.i_m.heads[0].CUS_DUE = null;
  // this.ss.i_m.heads[0].CUS_TYPE = null;

  // this.ss.i_m.flags[0].IS_LISTED_CUSTOMER = false;
  // this.ss.i_m.heads[0].CUS_ID = 0; 
  // this.ss.i_m.configs[0].IS_B2B = false;
  // this.ss.i_m.heads[0].INVOICE_NUMBER = this.invoice_number_b2c;
}

// cus_blur(res:any)
// {


  
//   if(this.cus_vend_model.ID != undefined)
//   {

 //this.ds.i_m.heads.CUS_ID =  this.cus_vend_model.ID 
 //this.ds.i_m.flags.is_listed_customer = true;


 //this.ds.i_m.heads.CUSTOMER_NAME =  this.cus_vend_model.NAME ;
 //this.ds.i_m.heads.CUSTOMER_STREET = this.cus_vend_model.STREET;
 //this.ds.i_m.heads.CUSTOMER_CITY  = this.cus_vend_model.CITY;
 //this.ds.i_m.heads.CUSTOMER_PHONE = this.cus_vend_model.PH;

 // this.ds.i_m.heads[0].CUSTOMER_PHONE2 = this.cus_vend_model.MOBILE;
 //this.ds.i_m.heads.CUSTOMER_GST_IN = this.cus_vend_model.GSTIN;
 //this.ds.i_m.heads[0].CUS_DUE = this.cus_vend_model.OPENING_BALANCE;
 //this.ds.i_m.heads.CUS_TYPE = this.cus_vend_model._TYPE;

//  if(this.ds.i_m.heads.CUS_TYPE === 5)
//  {
//    this.entry_id = 3
//  }
//  else if(this.ds.i_m.heads.CUS_TYPE === 6)
//  {
//   this.entry_id = 4

//  }
//  else if(this.ds.i_m.heads.CUS_TYPE === 1 && this.ds.i_m.heads.CUSTOMER_GST_IN != '' && this.ds.i_m.heads.CUSTOMER_GST_IN != null )
//  {
//   this.entry_id = 1

//  }
//  else
//  {
//   this.entry_id = 2
//  }

//  if(this.ds.i_m.heads.CUSTOMER_GST_IN != '' && this.ds.i_m.heads.CUSTOMER_GST_IN != null )
// {
  
// this.ds.i_m.flags.is_b2b = true;

// }
// else
// {
  
  
//  this.ds.i_m.flags.is_b2b = false;

//}

 

  //}


//else
  //{
  //  this.ds.i_m.heads.CUS_ID = 0;  
    //this.ds.i_m.heads.CUSTOMER_NAME  = res
    //this.ds.i_m.flags.is_listed_customer = false;
  //}







//}

  
  clearDate(): void {
    this.ngxdp.clearDate();
  }

  setDate(): void {
    // Initialize single date (today)
    if (this.myDatePickerOptions.dateRange) {
      alert("Date range mode is enabled! Change mode to single date before set single date.");
      return;
    }

    this.model_date = {isRange: false, singleDate: {jsDate: new Date()}};
  }






  onDisableToday(checked: boolean): void {
    let d: Date = new Date();
    let copy = this.getCopyOfOptions();
    copy.disableDates = checked ? [{year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()}] : [];
    this.myDatePickerOptions = copy;
  }

  onMarkToday(checked: boolean): void {
    let d: Date = new Date();
    let copy = this.getCopyOfOptions();
    copy.markDates = checked ? [{
      dates: [{year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()}],
      color: '#C30000'
    }] : [];
    this.myDatePickerOptions = copy;
  }








  onFocusInputOnDateSelect(checked: boolean): void {
    let copy = this.getCopyOfOptions();
    copy.focusInputOnDateSelect = checked;
    this.myDatePickerOptions = copy;
  }




  onShowFooterBar(checked: boolean) {
    let copy = this.getCopyOfOptions();
    copy.showFooterToday = checked;
    this.myDatePickerOptions = copy;
  }

 




  onDisableInput(checked: boolean) {
    this.disabled = checked;
  }




  


  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }

  
  // callbacks
  onDateChanged(event: IMyDateModel): void {
   console.log('onDateChanged(): ', event);
   this.ds.i_m.heads.INVOICE_DATE = event.singleDate.jsDate;
   console.log('onDate',this.ds.i_m.heads.INVOICE_DATE);
 
    }
    onInputFieldChanged(event: IMyInputFieldChanged): void {
    //  console.log('onInputFieldChanged(): Value: ', event.value, ' - dateFormat: ', event.dateFormat, ' - valid: ', event.valid);
      this.validDate = event.valid;
      this.inputText = event.value;
    }
  


 
  
 //   this.ds.i_m.heads.INVOICE_DATE = this.model_2.singleDate.jsDate;
  


}

