<app-header>

</app-header>
  <!-- The content section starts here -->
  <style type="text/css">
   
</style>








 <section class="">
        <div class="container about-us-area about-page">
            <div class="row">
                <div class="col-12">
                    <div class="col-12">
                        <div class="events-title pt-5 col-12">
                            <h2 class="malayalam text-center">ക്ഷേത്രം</h2>
                        </div>
                    </div>
                    <div class="col-12 pt-3">
                      <div class="col-md-6  pull-left">
                        <p class="malayalam text-justify text-size">
                            തിരുവനന്തപുരം ജില്ലയിൽ നഗരത്തിന്റെ ഒരു ഭാഗത്ത്&zwnj;  "ശ്രീകാര്യം" എന്ന പ്രദേശത്തിന്റെ  ഹൃദയ ഭാഗത്ത്&zwnj; ദേശിയ പാതയോടു ചേർന്ന് പ്രകൃതി അതിന്റെ സകല സൗന്ദര്യവും ദേവചൈതന്യത്തോടു ചേർത്ത് വച്ചിരിക്കുന്ന മനോഹരമായ സ്ഥലത്താണ് ശ്രീമഹാദേവൻ വിരാജിക്കുന്നത്. ഒരു കാലത്ത് വലിയ പാറക്കൂട്ടങ്ങൾ നിറഞ്ഞ വളരെ വിശാലമായ ജലാശയമായിരുന്നു (കുളം) ഇവിടം. ഈ ജലാശയത്തിന് നടുവിലാണ് സ്വയം ഭൂലിംഗ രൂപത്തിൽ ദേവ ചൈതന്യം പ്രത്യക്ഷീഭവിച്ചത്. കാലക്രമേണ ഈ ഭാഗം ക്ഷേത്രമായി മാറുകയും ചെയ്തു. വിശാലമായ കുളത്തിന് നടുക്ക് മഹാദേവൻ ഇളകൊള്ളുന്നതുകൊണ്ട് (വസിക്കുന്നതുകൊണ്ട്) <strong> 'ഇളം കുളം' മഹാദേവനായി</strong>.
                          </p>
                      </div>
                      <div class="col-md-6 pull-right">
                            <div class="about-thumbnail overrideimgeffect">
                                <img src="http://www.elamkulamsreemahadevatemple.com/images/about/1.jpg" alt="aboutimage1">
                            </div>
                      </div>

                      <div class="col-md-12" style="clear: both">
                        <p class="malayalam text-justify text-size">
                            നഗരകേന്ദ്രത്തിൽ നിന്ന് ഏകദേശം 8  കിലോമീറ്റർ പടിഞ്ഞാറുമാറി
                            "ശ്രീകാര്യം" എന്ന നഗര ഭാഗത്ത്&zwnj; ദേശീയ പാതയിൽ വലിയ
                            ക്ഷേത്രഗോപുരത്തിനകത്ത്&zwnj;  50 മീറ്റർ ഉള്ളിൽ അവിടവിടെ
                            പാറക്കെട്ടുകളോടുകൂടിയ  അതിവിശാലവും മനോഹരവുമായ
                            കുളത്തിന് ഓരം ചേർന്ന് ഇളംകുളം ശ്രീമഹാദേവ ക്ഷേത്രം സ്ഥിതി
                            ചെയുന്നു.
                        </p>
                      </div>
                      <div class="col-md-12">
                        <p class="malayalam text-justify text-size">
                            AD പത്താം ശതകത്തിൽ അവസാനിച്ച
                            'ആയി' രാജവംശത്തിന്റെ ഭരണ കാലത്താണ്
                            ഈ ക്ഷേത്ര ശ്രീകോവിൽ നിർമിച്ചിട്ടുള്ളത് എന്ന് ക്ഷേത്ര നിർമ്മാണ
                            രീതി വെളിവാക്കുന്നു. വളരെ വിശേഷപ്പെട്ടതും അപൂർവവുമായ
                            ഗജപൃഷ്ഠാകൃതിയിൽ  ഉള്ള ശ്രീകോവിൽ ഇതിന് ദൃഷ്ടാന്തമാണ്.
                            'ആയി' രാജവംശത്തിന്റെ തിരുവല്ല മുതൽ നാഗർകോവിൽ വരെ
                            വ്യാപിച്ചു കിടന്ന രാജ്യത്തിന്റെ  നാഞ്ചിനാട്  എന്ന പ്രദേശത്താണ്
                            ഈ ക്ഷേത്രം ഉത്ഭവിച്ചത്. ഈ കാലഘട്ടവുമായി യോജിക്കുന്ന രീതിയിൽ
                            ഉള്ള ക്ഷേത്ര നിർമ്മാണ മാതൃക ക്ഷേത്രത്തിന്  ആയിരത്താണ്ടുകൾ
                            പഴക്കമുണ്ടെന്ന് വെളിവാക്കുന്നു.
                          </p>
                      </div>

                      <div class="col-md-6 pull-right">
                        <p class="malayalam text-justify text-size">
                            ഇന്ന് അതി വിശാലവും മനോഹരവുമായ കുളത്തിനു നടുവിൽ
                            ഭഗവാന് അഭിമുഖമായി, പടിഞ്ഞാറ് ദർശനമായി തൊഴുകൈകളോടെ
                            രുദ്രാവതാരമായ  ഹനുമാൻ സ്വാമിയും, ഭഗവാന്റെ വലതു ഭാഗത്തായി
                            'ഒക്കത്ത്  ഗണപതിയും', നാലമ്പലത്തിനു പുറത്ത് പത്നീ സമേതനായി
                            ശാസ്താവും, ഭദ്ര-ദുർഗ്ഗ-അന്നപൂർണേശ്വരി ഭാവങ്ങളിൽ ദേവിമാരും,
                            തെക്കു കിഴക്കായി ദേവിപ്രകൃതി  സ്വരൂപമായി  യക്ഷിയമ്മയും
                            കുടികൊള്ളുന്നു. ക്ഷേത്രത്തിന്റെ ഉല്പത്തി  കാലഘട്ടങ്ങളിൽ
                            നാഗർകാവ് ആയിരുന്നു ഈ പ്രദേശം എന്ന് പറയപ്പെടുന്നു.
                            അതിന്&zwnj; ഉപോത്ബലകമായി വളർന്നുകൊണ്ടിരിക്കുന്ന നാഗർകാവ്
                            ക്ഷേത്രത്തിന്റെ വടക്ക് ഭാഗത്തും  കാണപ്പെടുന്നു.
                        </p>
                      </div>
                      <div class="col-md-6 pull-left">
                        <div class="about-thumbnail overrideimgeffect">
                            <img src="http://www.elamkulamsreemahadevatemple.com/images/about/2.jpg" alt="aboutimage2">
                        </div>
                     </div>

                    </div>

                </div>
            </div>
        </div>

</section>
<app-footer></app-footer>