<div>
      
    
    
        <!-- <div class="buttondiv">
            <button style="cursor: pointer" type="button" (click)="dp.toggleCalendar()">Toggle</button>
            <button style="cursor: pointer" type="button" (click)="dp.openCalendar()">Open</button>
            <button style="cursor: pointer" type="button" (click)="dp.closeCalendar()">Close</button>
            <button style="cursor: pointer" type="button" (click)="clearDate()">Clear</button>
            <button style="cursor: pointer" type="button" (click)="setDate()">Set date</button>
          <button style="cursor: pointer" type="button" (click)="setDateRange()">Set date range</button>
        </div> -->
    
    
        <table class="pickertable">
            <tr>
                <td>
                    <form>
                  
    
                      <div class="input-group">
                        <input class="form-control" placeholder="{{!myDatePickerOptions.dateRange ? 'Select a date' : 'Select a date range'}}" angular-mydatepicker name="mydate"
                               [(ngModel)]="model" [options]="myDatePickerOptions" [defaultMonth]="defMonth" [locale]="locale" #dp="angular-mydatepicker"
                               (dateChanged)="onDateChanged($event)" (calendarViewChanged)="onCalendarViewChanged($event)"
                               (calendarToggle)="onCalendarToggle($event)" (inputFieldChanged)="onInputFieldChanged($event)"
                               (rangeDateSelection)="onRangeDateSelection($event)" [attr.disabled]="disabled?'':null" autocomplete="off"/>
                        <div class="input-group-append">
                          <button type="button" class="btn btn-secondary" (click)="dp.clearDate()">
                            <i class="fa fa-close"></i>
                          </button>
                        </div>
                        <div class="input-group-append">
                          <button type="button" class="btn btn-secondary" (click)="dp.toggleCalendar()">
                            <i class="fa fa-calendar-o"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                </td>
                <!-- <td>
                    <div *ngIf="inputText !== ''" class="border" [ngClass]="{'okDate': validDate && selectedTextNormal.length, 'invalidDate': !validDate}">
                        <span *ngIf="validDate">{{selectedTextNormal}}</span>
                        <span *ngIf="!validDate">Invalid date</span>
                    </div>
                </td> -->
        </table>
    
    </div>