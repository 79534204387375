export interface Hero {
    DESCRIPTION: string;
    HSN_CODE: string;
    AVAIL_QTY : number;
    NET_PRICE : number;
    CATGERY : string;
  }
  

  export interface Zero {
    id: number;
    USER_NAME: string;
    PASSWORD : string;
 
  }
  

  export class invoice_master {
    constructor(
    
    public  heads:invoice_head,
    public  items:invoice_items[],
    public  varibs:invoice_varibs,
    public  tails:invoice_tails
  
    
  
  
    ) {} }

    export class invoice_varibs {
      constructor(
      
      public  i:number,
      public  k:number,
    
      
    
    
      ) {} }

    


    export class invoice_items
    {
      constructor(
     public SI_NO : number,
     public NAME : string,
     public PRO_ID: number,
     public MRP: number,
     public QTY: number,
     public UNIT: string,
     public PRICE: number,
     public NET_PRICE : number,
     public BAR_CODE:string,
     public ENTRY_NO: number
     
     

      ) {}
    
    }

  export class invoice_head {
    constructor(
   public COM_ID : string,
   public SESSION_ID : string,
   public INVOICE_DATE : Date,
   public INVOICE_NUMBER : number,
   public CUS_NAME : string,
   public CUS_ADDRESS : string,
   public CUS_MOB : string,
   public CUS_GST_NO : string,
  

    ) {}
  
  }

  export class invoice_tails {
    constructor(

   public SUB_TOTAL : number,
   public YOUSAVE_TOTAL : number,
   public MRP_TOTAL : number,   
   public GRAND_TOTAL : number,
   public PAYED_TODAY : number,
   public BAL_PAY : number,
   public BILL_DUE : number
   

    ) 
    {}
  
  }



  export interface invoice_slave {
    DESCRIPTION: string;
    HSN_CODE: string;
    AVAIL_QTY : number;
    NET_PRICE : number;
    CATGERY : string;
    POSTED_BY : string;
    INVOICE_NUMBER : number;
    INVOICE_STRING : string;
  }