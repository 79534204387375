import { Component, OnInit,Renderer2, ViewChild, ChangeDetectorRef,ElementRef } from '@angular/core';
import { DataService } from '../../services/data.service'
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

import { Router } from '@angular/router';
import { pdt_model,figure } from '../new/model';


@Component({
  selector: 'app-edit-bills',
  templateUrl: './edit.html',
  styleUrls: ['./edit.css']
})
export class EditbillsComponent implements OnInit {

 


  view : boolean = false;
  clicked : number = 0;
  ia : number = 0;
    ka : number = 0;
    modal : any;
    pro_qty_model : number;
    pro_np_model : number;
    pro_name : string;
    arrayOfKeyValues: any[] = [];
    product_array : pdt_model[] =[];
    isServer_res : boolean = false;
    pdtary : number [] = [];

    pro_id : number = 0;
    pro_catgery : string ;
    pro_hsn : string;
    pro_unit : string;
    pro_mrp : number;
    pro_tax : number;
    pro_np  : number;
    pro_cat : string;
    pro_qty : number = 1;
    pro_barcode : string;
    pro_price : number;
    arr_: number = 0;
    floor : number;
    frac : number;
    j:number;
    fig_tax : string ;
    fig_main : string ;
    total_qty : number = 0;
    gross_total : number = 0;
    temp_total_tax : number = 0;
    temp_total_mrp : number = 0 ;
    //fig_model = new figure(0,0,'');
    discount_amt : number = 0;
    push_disabled : boolean = false;
    push_disabled_enter : boolean = false;
    data_error_string : string;
    data_error_flag : number;
    isClickedOnce : boolean = false;
    b : number;
    recamt_amt : number = 0;
    bal_due   : number = 0;
    payed_amt : number = 0;

    @ViewChild("item_qty_model") _eq: ElementRef; 
    @ViewChild("autos") _en: ElementRef; 
    @ViewChild("disamt") _ed: ElementRef; 



  constructor(public ds : DataService,private formBuilder: FormBuilder, private rs: Router,
     private renderer: Renderer2, private cdr: ChangeDetectorRef  ) {

    
   }  
 

 
   ngOnInit(): void {


    this.reset()
    
    this.modal = document.getElementById("natives");
 
    this.get_products();


  }

  get_products()
{
  this.ds.get_all_products()
  .subscribe((jsonData) => { this._get_products(jsonData)
          },(err) => console.error(err),
           
          );
}

_get_products(json :any)
{               


this.arrayOfKeyValues = json;   
console.log('arrr',this.arrayOfKeyValues)
this.product_array = json;
this.isServer_res = true;            
   

//this._en.nativeElement.focus();

}


  reset()
  {
    this.ds.i_m.heads.CUS_NAME = 'Cash';
    this.ds.i_m.tails.GRAND_TOTAL = 0;
    this.ds.i_m.tails.MRP_TOTAL = 0;
    this.ds.i_m.tails.YOUSAVE_TOTAL = 0;
    this.ds.i_m.tails.PAYED_TODAY = null;
    this.ds.i_m.tails.BAL_PAY = 0;
  
    for(this.b=this.ds.i_m.items.length;this.b--;)
    {
      this.ds.i_m.items.pop();
    }
    this.get_bills();
  }




  get_bills()
  {
    this.ds.get_a_bill()
    .subscribe((jsonData) => { this._get_bills(jsonData)
            },(err) => console.error(err),
             
            );
  }
 


_get_bills(json :any)
{               



console.log('bills',json)


this.make_master(json.master)
this.make_slave(json.slave)



}


make_master(data)
{

console.log('data',data);

  this.ds.i_m.heads.INVOICE_DATE = data.ENTRY_DATE
  this.ds.i_m.heads.CUS_NAME = data.CUS_NAME
  this.ds.now_date = data.ENTRY_DATE;
  this.ds.now_id = true;
  this.ds.i_m.tails.GRAND_TOTAL = data.GRAND_TOTAL
  this.ds.i_m.tails.YOUSAVE_TOTAL = data.YOU_SAVE_TOTAL
  this.ds.i_m.tails.PAYED_TODAY = data.PAYED_AMT
  this.ds.i_m.tails.BAL_PAY = data.BAL_AMT

  this.ds.i_m.tails.BILL_DUE = data.DUE_AMT
 

}

make_slave(data)
{
   

    for(var i=0;i<data.length;i++)
    {
        this.stack_push();
    }  
    
    this.ia = data.length;

    for(var i=0;i<data.length;i++)
    {   
        this.ds.i_m.items[i].SI_NO = i+1;
        this.ds.i_m.items[i].BAR_CODE = data[i].BAR_CODE
        this.ds.i_m.items[i].NAME = data[i].NAME
        this.ds.i_m.items[i].MRP = data[i].MRP
        this.ds.i_m.items[i].QTY = data[i].QTY
        this.ds.i_m.items[i].UNIT = data[i].UNIT
        this.ds.i_m.items[i].PRICE = data[i].PRICE
        this.ds.i_m.items[i].PRO_ID = data[i].PRO_ID

        this.pdtary[i] = data[i].PRO_ID

    }
    this.calculation();
}




stack_push()
{
    this.ds.i_m.items.push({ SI_NO:this.ia+1,PRO_ID:0, NAME:'',QTY:1,MRP:0,
    PRICE:0,UNIT:'pc',BAR_CODE:'',NET_PRICE:0,ENTRY_NO:0})
}

pushItem_down()
{
    this.stack_push();
    this.ds.i_m.items[this.ia].BAR_CODE = this.pro_barcode;
    this.ds.i_m.items[this.ia].NAME = this.pro_name;
    this.ds.i_m.items[this.ia].QTY = this.pro_qty;
    this.ds.i_m.items[this.ia].UNIT = this.pro_unit;
    this.ds.i_m.items[this.ia].PRICE = this.pro_price
    this.ds.i_m.items[this.ia].PRO_ID = this.pro_id;
    this.ds.i_m.items[this.ia].MRP = this.pro_mrp;
    this.ds.i_m.items[this.ia].NET_PRICE = this.ds.i_m.items[this.ia].PRICE * this.ds.i_m.items[this.ia].QTY;
    
  
 
    this.ia = this.ia+1;
    this._en.nativeElement.value = null 
    this._en.nativeElement.focus(); 
    this.push_disabled = true;
    this.calculation();
}
pushItem()
{
    this.stack_push();
    this.ds.i_m.items[this.ia].BAR_CODE = this.pro_barcode;
    this.ds.i_m.items[this.ia].NAME = this.pro_name;
    this.ds.i_m.items[this.ia].QTY = this.pro_qty;  
    this.ds.i_m.items[this.ia].UNIT = this.pro_unit; 
    this.ds.i_m.items[this.ia].PRICE = this.pro_price;
    this.ds.i_m.items[this.ia].PRO_ID = this.pro_id;
    this.ds.i_m.items[this.ia].MRP = this.pro_mrp;
    this.ds.i_m.items[this.ia].NET_PRICE = this.ds.i_m.items[this.ia].PRICE * this.ds.i_m.items[this.ia].QTY;
    
 
    this.ia = this.ia+1;
    this.push_disabled = true;
    this._ed.nativeElement.focus(); 
     this.calculation();

    

}
  
change_item_name(i_n:any)
{
    console.log(i_n)
    
    let bi : any;
 
  bi = this.product_array.filter(xi=> xi.BAR_CODE  === i_n);
 
 
 
      if(bi.length == 1)
      {
 
       this.pro_id = bi[0].ID;
       this.pro_catgery = bi[0].CATGERY;
       this.pro_hsn = bi[0].HSN_CODE;
       this.pro_unit =  bi[0].UNIT;
       this.pro_mrp = bi[0].MRP;
       this.pro_tax = bi[0].TAX;
       this.pro_np =  bi[0].PRICE;
       this.pro_name = bi[0].DESCRIPTION;
       this.pro_barcode = i_n;
       this.pro_price = bi[0].PRICE
       this.pro_cat = bi[0].CATGERY;
 
 
         this.check_for_duplication(bi[0].ID)
 
       this._eq.nativeElement.focus(); 
       this.pro_qty = 1;
       this.push_disabled = false;
 
      }
 
}


check_for_duplication(id : number)
{

  if(this.ia === 0)
  {
   this.pdtary[this.ia] = id;
  }
  else
  {
   console.log('id aray', this.pdtary);

   var x = this.pdtary.indexOf(id,0)
   console.log('id',x)
   if(x < 0)
   {
    this.pdtary[this.ia] = id;
   }
   else
   {
     
      if(x === this.ia)
      {
  
      }
      else
      {
        var nun = x + 1;
        alert('Already Present in Position '+ nun);
      }
    
   }


  }


}


editItem(index:any)
{
    this.open_modal();
    this.ka = index;
    this.pro_qty_model = this.ds.i_m.items[index].QTY;
    this.pro_np_model = this.ds.i_m.items[index].PRICE;

    this.calculation();
    this.change_dit();
}
open_modal()
{
    this.modal.style.display = "block";
}

close_modal()
{
    this.ds.i_m.items[this.ka].QTY = this.pro_qty_model 
   this.ds.i_m.items[this.ka].PRICE = this.pro_np_model 

    this.modal.style.display = "none";

    this.calculation()
    this.change_dit();
}


calculation()
{
   this.ds.total_qty = 0;
   this.ds.mrp_total = 0;
   this.ds.price_total = 0;
   this.ds.i_m.tails.GRAND_TOTAL = 0;
   this.ds.i_m.tails.MRP_TOTAL = 0;
   this.ds.i_m.tails.YOUSAVE_TOTAL = 0;

   for(this.j=0;this.j<this.ds.i_m.items.length;this.j++)

   { 

    this.ds.i_m.items[this.j].NET_PRICE = this.ds.i_m.items[this.j].PRICE * this.ds.i_m.items[this.j].QTY;
    this.ds.mrp_total = (this.ds.mrp_total-0) + (this.ds.i_m.items[this.j].MRP * this.ds.i_m.items[this.j].QTY);
    this.ds.total_qty = (this.ds.total_qty-0) + (this.ds.i_m.items[this.j].QTY-0);
    this.ds.price_total = (this.ds.price_total-0) + (this.ds.i_m.items[this.j].NET_PRICE-0);


   } 
 
   this.ds.i_m.tails.GRAND_TOTAL = this.ds.price_total;
   this.ds.i_m.tails.MRP_TOTAL =  this.ds.mrp_total;
   this.ds.i_m.tails.YOUSAVE_TOTAL =  this.ds.mrp_total - this.ds.price_total;


   this.ds.floor =  Math.floor(this.ds.i_m.tails.GRAND_TOTAL);  
   this.ds.frac =  (this.ds.i_m.tails.GRAND_TOTAL) % 1;

     if(this.frac > .49)
     {
       this.frac = 1 - this.frac;
       this.floor = this.floor + 1;
     }

     this.ds.i_m.tails.GRAND_TOTAL =  this.ds.floor ;







   }


change_dit()
   {
        
        this.calculation();
       
        this.ds.i_m.tails.BAL_PAY = this.ds.i_m.tails.PAYED_TODAY  - this.ds.i_m.tails.GRAND_TOTAL;

        if(this.ds.i_m.tails.BAL_PAY < 0)
        {
          this.ds.i_m.tails.BILL_DUE = 0 - this.ds.i_m.tails.BAL_PAY
        }

        else
        {
          this.ds.i_m.tails.BILL_DUE = 0;
        }


       

   }

   RemoveItem(ipx:number)
{
    this.item_arrange(ipx);

}

item_arrange(index)
{

    console.log('ia',this.ia);

    console.log('index',index);

    for(this.arr_ = index ; this.arr_ <= this.ia-2 ; this.arr_ ++)
    {

        console.log('arr',this.arr_);
      
     this.ds.i_m.items[this.arr_].NAME = this.ds.i_m.items[this.arr_+1].NAME;
    
     this.ds.i_m.items[this.arr_].PRO_ID  = this.ds.i_m.items[this.arr_+1].PRO_ID;
    
     this.ds.i_m.items[this.arr_].QTY = this.ds.i_m.items[this.arr_+1].QTY;
     this.ds.i_m.items[this.arr_].UNIT = this.ds.i_m.items[this.arr_+1].UNIT;
    
     this.ds.i_m.items[this.arr_].PRICE = this.ds.i_m.items[this.arr_+1].PRICE;
     
   

     this.pdtary[this.arr_] =  this.pdtary[this.arr_+1];
    

    }

    this.ds.i_m.items.pop();
    this.pdtary.pop();
    this.ia = this.ia-1;

   this.calculation();
   this.change_dit()

}


save_invoice()
{

 
  console.log('dtat',this.ds.i_m);
      
  var cdb = 0;
  this.data_error_flag = 0;

  if(  this.ds.i_m.items.length === 0 )
  {

    this.data_error_flag = 1;
    this.data_error_string = 'No item in the list:  '+(cdb+1);

  }

  else
  {

  for(cdb ; cdb < this.ds.i_m.items.length ; cdb ++)
  {
 
    if(  this.ds.i_m.items[cdb].PRICE === 0 || this.ds.i_m.items[cdb].PRICE === null)
    {

      this.data_error_flag = 1;
      this.data_error_string = 'Price should be greater than zero:  '+(cdb+1);

    }

 




    if(  this.ds.i_m.items[cdb].QTY === null || this.ds.i_m.items[cdb].QTY === 0)
    {

      this.data_error_flag = 1;
      this.data_error_string = 'Quantity should not be Null or Zero on serial no :  '+(cdb+1);

    }
    
 

  }

  if(this.ds.i_m.tails.PAYED_TODAY == null)
  {
    this.data_error_flag = 1;
    this.data_error_string = 'Amount Payed should not be Null';

  }


  }
 

if(this.data_error_flag === 0)

{

 this.push_disabled_enter = true;


  this.ds.update_invoice(this.ds.i_m)
  .subscribe((jsonData) => { this.get_res(jsonData)
                  },(err) => console.error(err),
                 
                  );

}

else
{
  alert(this.data_error_string);
}

}


get_res(res:any)
{
  if( res.success === true)
  {

   this.ds.i_m.heads.INVOICE_NUMBER = res.mas_id;
   
   setTimeout(() => 
{
  window.print();
  alert('Invoice Saved')
  
},
1000);
    

     setTimeout(() => 
{
 
  this.rs.navigate(['/home']);
},
4000);
   
   

   }

   else
   {
     alert(res.msg)
     this.rs.navigate(['/home']);
   }
}




}

