import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.html',
  styleUrls: ['./contact.css']
})

export class Contactcomponent implements OnInit {

    constructor() { }

  

    ngOnInit(): void {
    }
}  
