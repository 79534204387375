
 <header class="header-area">

    <!-- ***** Top Header Area ***** -->
    <div class="top-header" style="background-color:#e4c2d2;height:100%;">
    
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="top-header-content d-flex flex-wrap align-items-center justify-content-between">
                        <!-- Top Header Meta -->
                        
                        <div class="top-header-meta d-flex flex-wrap">
                            
                            <a href="http://www.elamkulamsreemahadevatemple.com/temple-home" class="nav-brand"><b class="malayalam" style="font-size: 18px; ">
                            Horn Bill 4.0.1 lite beta </b></a>
                            <!-- Social Info -->
                            
                        </div>
                        <!-- Top Header Meta -->

                    </div>
                </div>
                <div class="col-md-6 text-right d-none d-sm-block">
                    <p class="pt-3" style="font-size: 12px;color: #000000;font-weight: bold">
                        
                        The Official Accounting App from  Core Soft
                    </p>
                    

                </div>
                
            </div>
        </div>
    </div>
  </header>




  




  
<nav class="navbar mynav sticky-top navbar-expand-lg">
  <a class="navbar-brand" href="#">  <img src="../../assets/icons/hb4_logo.jpg" style="width: 55px;
    height: 50px;" />  </a>
  <button class="navbar-toggler" type="button"
   data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon">   <img src="../../assets/icons/menu4.svg" style="width: 25px;
      height: 25px;" /> </span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" >
    <ul class="navbar-nav mr-auto">
      


      <!-- <li class="nav-item"> -->
        <!-- <a class="nav-link" href="#">Temple</a> -->

        <li class="nav-item dropdown ">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown"  
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Invoice
          </a>

        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#/new_invoice"> New  </a>
          <a class="dropdown-item" href="#/view-bills"> View bills  </a>         
         
 
         
             
        </div>

        </li>

        <li class="nav-item dropdown ">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown"  
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Purchase
          </a>

        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#/purchase-new"> New  </a>
          <a class="dropdown-item" href="#/purchase-view-all"> View Purchases  </a>         
         
 
         
             
        </div>

        </li>

        <li class="nav-item dropdown ">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown"  
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Expenses
          </a>

        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#/expense-new"> New  </a>
          <a class="dropdown-item" href="#/expense-view-all"> View Expenses  </a>         
         
 
         
             
        </div>

        </li>




        <li class="nav-item dropdown ">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown"    role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Product
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
            <a class="dropdown-item" href="#/add-product">New</a>
            <a class="dropdown-item" href="#/product-update"> Update </a>
           
        
  
           
            
          </div>
        </li>


    
    

            
        <li class="nav-item">
          <a class="nav-link" href="#/report-main"> Reports </a> 
        </li>





        <li class="nav-item dropdown ">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown"    role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            settings
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
            
            <a class="dropdown-item" (click)=back_up()> backup </a>
          </div>
        </li>

     



    


       
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://csweb.in"> Contact Us </a> 
        </li>

      
      
        <li class="nav-item">
            <a class="nav-link" (click)=Log_out()> LogOut </a> 
          </li>





    </ul>

  </div>
</nav>  
