import { Component, OnInit,Renderer2, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../services/data.service';



import {AngularMyDatePickerDirective, DefaultView, IAngularMyDpOptions, 
    IMyCalendarViewChanged, IMyDate, IMyDateModel, IMyDefaultMonth, IMyInputFieldChanged, 
    IMyMarkedDate, IMyRangeDateSelection, CalAnimation} from  'angular-mydatepicker';




@Component({
  selector: 'app-test2',
  templateUrl: './test2.html',
  styleUrls: ['./test2.css']
})
export class TestComponent2 implements OnInit {


    public myDatePickerOptions: IAngularMyDpOptions = {
        dateRange: false,
        dateFormat: 'dd/mm/yyyy',
        firstDayOfWeek: 'mo',
        sunHighlight: true,
        markCurrentDay: true,
        alignSelectorRight: false,
        openSelectorTopOfInput: true,
        minYear: 2019,
        maxYear: 2200,
        showSelectorArrow: true,
        monthSelector: true,
        yearSelector: true,
        satHighlight: false,
        highlightDates: [],
        disableDates: [],
        disableHeaderButtons: true,
        showWeekNumbers: false,
        disableDateRanges: [
          {begin: {year: 2016, month: 10, day: 5}, end: {year: 2016, month: 10, day: 7}},
          {begin: {year: 2016, month: 10, day: 10}, end: {year: 2016, month: 10, day: 12}}
        ],
        disableUntil: {year: 0, month: 0, day: 0},
        disableSince: {year: 2022, month: 2, day: 2},
        disableWeekdays: [],
        markDates: [],
        markWeekends: <IMyMarkedDate>{},
        selectorHeight: '200px',
        selectorWidth: '220px',
        closeSelectorOnDateSelect: true,
        closeSelectorOnDocumentClick: true,
        showMonthNumber: true,
        appendSelectorToBody: false,
        focusInputOnDateSelect: true,
        dateRangeDatesDelimiter: " - ",
        defaultView: DefaultView.Date,
        showFooterToday: false,
        calendarAnimation: {in: CalAnimation.ScaleCenter, out: CalAnimation.Fade},
        rtl: false,
        stylesData:
          {
            selector: '',
            styles: ''
          }
      };
    









 

  public model_2: IMyDateModel = null; 

  selectedDate: any;

  @ViewChild('dp') ngxdp: AngularMyDatePickerDirective;

  public selectedTextNormal: string = '';

  public disabled: boolean = true;
  public validDate: boolean = false;
  public inputText: string = "";

  public model: IMyDateModel = null;   // not initial date set

  public defMonth: IMyDefaultMonth = {
    defMonth: ''
  };

  public selectorSizes: Array<string> = new Array('266px x 266px', '200px x 220px', '260px x 290px');
  public defaultViews: Array<string> = new Array('date', 'month', 'year');
  public calAnimations: Array<string> = new Array('None', 'Fade', 'ScaleTop-ScaleCenter', 'ScaleCenter-ScaleTop', 'Rotate', 'FlipDiagonal');
  public styleColor: Array<string> = new Array('Default', 'Grey', 'Blue', 'Green', 'Red', 'Yellow', 'Dark');

  public locale: string = 'en';






  



  constructor(private ds : DataService,
     private renderer: Renderer2, private cdr: ChangeDetectorRef  ) {

    
   }  
 

 

  ngOnInit(): void {
    console.log('onInit(): SampleDatePickerNgModel');
  }



  
  clearDate(): void {
    this.ngxdp.clearDate();
  }

  setDate(): void {
    // Initialize single date (today)
    if (this.myDatePickerOptions.dateRange) {
      alert("Date range mode is enabled! Change mode to single date before set single date.");
      return;
    }

    this.model = {isRange: false, singleDate: {jsDate: new Date()}};
  }






  onDisableToday(checked: boolean): void {
    let d: Date = new Date();
    let copy = this.getCopyOfOptions();
    copy.disableDates = checked ? [{year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()}] : [];
    this.myDatePickerOptions = copy;
  }

  onMarkToday(checked: boolean): void {
    let d: Date = new Date();
    let copy = this.getCopyOfOptions();
    copy.markDates = checked ? [{
      dates: [{year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()}],
      color: '#C30000'
    }] : [];
    this.myDatePickerOptions = copy;
  }








  onFocusInputOnDateSelect(checked: boolean): void {
    let copy = this.getCopyOfOptions();
    copy.focusInputOnDateSelect = checked;
    this.myDatePickerOptions = copy;
  }




  onShowFooterBar(checked: boolean) {
    let copy = this.getCopyOfOptions();
    copy.showFooterToday = checked;
    this.myDatePickerOptions = copy;
  }

 




  onDisableInput(checked: boolean) {
    this.disabled = checked;
  }




  


  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }

  
  // callbacks
  onDateChanged(event: IMyDateModel): void {
    console.log('onDateChanged(): ', event);
    if (!event.isRange) {
      let {date, jsDate, formatted, epoc} = event.singleDate;
      if (formatted !== '') {
        this.selectedTextNormal = 'Formatted: ' + formatted + ' - epoc timestamp: ' + epoc;
        this.validDate = true;
        this.inputText = formatted;
      }
      else {
        this.selectedTextNormal = '';
      }
    }
    else {
      let {formatted} = event.dateRange;
      if (formatted !== '') {
        this.selectedTextNormal = 'Formatted: ' + formatted;
        this.validDate = true;
        this.inputText = formatted;
      }
      else {
        this.selectedTextNormal = '';
      }
    }
  }

  onInputFieldChanged(event: IMyInputFieldChanged): void {
    console.log('onInputFieldChanged(): Value: ', event.value, ' - dateFormat: ', event.dateFormat, ' - valid: ', event.valid);
    this.validDate = event.valid;
    this.inputText = event.value;
  }

  onCalendarToggle(event: number): void {
    console.log('onCalendarToggle(): Reason: ', event);
  }

  onCalendarViewChanged(event: IMyCalendarViewChanged): void {
    console.log('onCalendarViewChanged(): Year: ', event.year, ' - month: ', event.month, ' - first: ', event.first, ' - last: ', event.last);
  }

  onRangeDateSelection(event: IMyRangeDateSelection): void {
    console.log('onRangeDateSelection(): event: ', event);
  }


  










  

}

