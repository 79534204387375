import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Hero,Zero } from './model';

import { HttpErrorHandler, HandleError } from './http-error-handler';

import { invoice_head,invoice_varibs,invoice_master,invoice_tails } from './model';



const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable()
export class DataService {

 
  post_demo : any = {invo_string : "",is_b2b:false,com_id:null,e_no:0}
  report_demo : any = {report_type : "",from_date:'',to_date:'',e_no:0}
  com_id : string = 'com_1';
  admin_url = 'http://lite.csweb.in/hblite/admin_routes/';
  get_url = 'http://lite.csweb.in/hblite/get/';
  post_url = 'http://lite.csweb.in/hblite/post/';
  post_url_local = 'http://localhost:8088/api/post/';
  
  total_qty : number = 0;
  mrp_total : number = 0;
  price_total : number = 0;
  floor : number = 0;
  frac : number = 0;
  now_date : Date;
  now_d : boolean = false;
  now_id : boolean = false;
  COMPANY_NAME: string
  COMPANY_ADR_1 : string
  COMPANY_ADR_2 : string
  COMPANY_ADR_3 : string
  COMPANY_PH : string
  COMPANY_PH2 : string




  i_h = new invoice_head(null,null,new Date(),null,null,null,null,null)
  i_v = new invoice_varibs(0,0)
  i_t = new invoice_tails(0,0,0,0,null,0,0)
  i_m = new invoice_master(this.i_h,[],this.i_v,this.i_t) ; 



  private handleError: HandleError;
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) { 
    this.handleError = httpErrorHandler.createHandleError('HeroesService');
  }

  getData() {
    return this.http.get('/assets/config.json');
  }

  Push_Item()
  {
    this.i_m.items.push({SI_NO:0,NAME:'',PRO_ID:0,MRP:0,PRICE:0,BAR_CODE:'',UNIT:'',QTY:0,NET_PRICE:0,ENTRY_NO:0})
  }

  save_expense(model:any)
  {
    model.com_id = this.com_id
    console.log('model',model);   
    let body = JSON.stringify(model);   
    return this.http.post<any>(this.post_url + 'save_expense', body, httpOptions)
    .pipe(
      catchError(this.handleError('save_expense', body))
    );
  }
  update_expense(model:any)
  {
    model.com_id = this.com_id
    console.log('model',model);   
    let body = JSON.stringify(model);   
    return this.http.post<any>(this.post_url + 'update_expense', body, httpOptions)
    .pipe(
      catchError(this.handleError('update_expense', body))
    );
  }

  add_unit(model:any)
  {
    
    model.com_id = this.com_id
    console.log('model',model);
   
    let body = JSON.stringify(model); 
  
    return this.http.post<any>(this.post_url + 'add_unit', body, httpOptions)
    .pipe(
      catchError(this.handleError('login', body))
    );
  }

  get_all_category()
  {
    this.post_demo.com_id = this.com_id;
    let body = JSON.stringify(this.post_demo); 
  
    return this.http.post<any>(this.post_url + 'get_all_category', body, httpOptions)
    .pipe(
      catchError(this.handleError('get_all_category', body))
    );

  }

  get_all_expenses()
  {
  this.post_demo.com_id = this.com_id;
  let body = JSON.stringify(this.post_demo); 

  return this.http.post<any>(this.post_url + 'get_all_expenses', body, httpOptions)
  .pipe(
    catchError(this.handleError('get_all_category', body))
  );

}
get_specific_expense()
{
  this.post_demo.com_id = this.com_id;
  this.post_demo.e_no = this.i_m.heads.INVOICE_NUMBER;
  let body = JSON.stringify(this.post_demo); 

  return this.http.post<any>(this.post_url + 'get_specific_expense', body, httpOptions)
  .pipe(
    catchError(this.handleError('get_all_category', body))
  );
}

  add_expense_category(model:any)
  {
    
    model.com_id = this.com_id
    console.log('model',model);
   
    let body = JSON.stringify(model); 
  
    return this.http.post<any>(this.post_url + 'add_expense_category', body, httpOptions)
    .pipe(
      catchError(this.handleError('add_expense_category', body))
    );
  }
  get_all_third_party()
  {
    this.post_demo.com_id = this.com_id;
    let body = JSON.stringify(this.post_demo); 
  
    return this.http.post<any>(this.post_url + 'get_all_third_party', body, httpOptions)
    .pipe(
      catchError(this.handleError('get_all_third_party', body))
    );
  }

  add_third_party(model:any)
  {
    model.com_id = this.com_id
    console.log('model',model);
   
    let body = JSON.stringify(model); 
  
    return this.http.post<any>(this.post_url + 'add_third_party', body, httpOptions)
    .pipe(
      catchError(this.handleError('add_third_party', body))
    );
  }

  collection_report(model:any)
  {
    
    model.com_id = this.com_id
    console.log('model',model);
   
    let body = JSON.stringify(model); 
  
    return this.http.post<any>(this.post_url + 'collection_report', body, httpOptions)
    .pipe(
      catchError(this.handleError('login', body))
    );
  }
  purchase_report(model:any)
  {
    
    model.com_id = this.com_id
    console.log('model',model);
   
    let body = JSON.stringify(model); 
  
    return this.http.post<any>(this.post_url + 'purchase_report', body, httpOptions)
    .pipe(
      catchError(this.handleError('login', body))
    );
  }

  sales_report(model:any)
  {
    
    model.com_id = this.com_id
    console.log('model',model);
   
    let body = JSON.stringify(model); 
  
    return this.http.post<any>(this.post_url + 'sales_report', body, httpOptions)
    .pipe(
      catchError(this.handleError('login', body))
    );
  }


  

   

  get_back_up(model:any)
  {
    
    
    console.log('model',model);


   
    let body = JSON.stringify(this.post_demo); 
  
    return this.http.post<any>(this.post_url + 'backup', body, httpOptions)
    .pipe(
      catchError(this.handleError('login', body))
    );
  }

  

  add_product(model:any)
  {
    
    model.com_id = this.com_id
    console.log('model',model);
   
    let body = JSON.stringify(model); 
  
    return this.http.post<any>(this.post_url + 'add_product', body, httpOptions)
    .pipe(
      catchError(this.handleError('login', body))
    );
  }

  add_product_category(model:any)
  {
    
    model.com_id = this.com_id
    console.log('model',model);
   
    let body = JSON.stringify(model); 
  
    return this.http.post<any>(this.post_url + 'add_product_category', body, httpOptions)
    .pipe(
      catchError(this.handleError('login', body))
    );
  }

  get_product_category()
  {

    this.post_demo.com_id = this.com_id
    let body = JSON.stringify(this.post_demo); 
  
    return this.http.post<any>(this.post_url + 'get_all_category', body, httpOptions)
    .pipe(
      catchError(this.handleError('login', body))
    );

  }

  get_all_units(): Observable<any> {

    this.post_demo.com_id = this.com_id
    let body = JSON.stringify(this.post_demo); 
  
    return this.http.post<any>(this.post_url + 'get_all_units', body, httpOptions)
    .pipe(
      catchError(this.handleError('login', body))
    );
  }

  get_all_products(): Observable<any> {

    this.post_demo.com_id = this.com_id
    let body = JSON.stringify(this.post_demo); 
  
    return this.http.post<any>(this.post_url + 'get_all_products', body, httpOptions)
    .pipe(
      catchError(this.handleError('login', body))
    );
  }

  get_all_purchases() : Observable<any> {

    this.post_demo.com_id = this.com_id
    let body = JSON.stringify(this.post_demo); 
  
    return this.http.post<any>(this.post_url + 'get_all_purchases', body, httpOptions)
    .pipe(
      catchError(this.handleError('login', body))
    );
  }

  get_all_bills(): Observable<any> {

    this.post_demo.com_id = this.com_id
    let body = JSON.stringify(this.post_demo); 
  
    return this.http.post<any>(this.post_url + 'get_all_bills', body, httpOptions)
    .pipe(
      catchError(this.handleError('get_all_bills', body))
    );
  }

  get_a_bill(): Observable<any> {

    this.post_demo.e_no = this.i_m.heads.INVOICE_NUMBER
    let body = JSON.stringify(this.post_demo);  

  
    return this.http.post<any>(this.post_url + 'get_a_bill', body, httpOptions)
    .pipe(
      catchError(this.handleError('get_a_bill', body))
    );
  }




  get_a_purchase_() : Observable<any> {

    this.post_demo.e_no = this.i_m.heads.INVOICE_NUMBER
    let body = JSON.stringify(this.post_demo);  

  
    return this.http.post<any>(this.post_url + 'get_a_purchase_bill', body, httpOptions)
    .pipe(
      catchError(this.handleError('get_a_purchase_bill', body))
    );
  }

  get_customers_list(): Observable<any> {

    this.post_demo.com_id = this.com_id
    let body = JSON.stringify(this.post_demo); 
     

  
    return this.http.post<any>(this.post_url + 'get_all_cus_vendors', body, httpOptions)
    .pipe(
      catchError(this.handleError('login', body))
    );
  }


 

  
  login (model: any): Observable<any> {
    let body = JSON.stringify(model); 
    return this.http.post<any>(this.admin_url + 'user_login', body, httpOptions)
      .pipe(
        catchError(this.handleError('login', body))
      );
  }

  add_new_entry (model:any): Observable<any> {
    let body = JSON.stringify(model); 
    return this.http.post<any>(this.admin_url + 'test_date', body, httpOptions)
      .pipe(
        catchError(this.handleError('login', body))
      );
  }

  post_invoice (model:any): Observable<any> {
    let body = JSON.stringify(model); 
    console.log('body',body);
    return this.http.post<any>(this.post_url + 'post_invoice', body, httpOptions)
      .pipe(
        catchError(this.handleError('login', body))
      );
  }

  post_purchase (model:any): Observable<any> {
    let body = JSON.stringify(model); 
    return this.http.post<any>(this.post_url + 'post_purchase', body, httpOptions)
      .pipe(
        catchError(this.handleError('login', body))
      );
  }

  update_purchase (model:any): Observable<any> {
    let body = JSON.stringify(model); 
    return this.http.post<any>(this.post_url + 'update_purchase', body, httpOptions)
      .pipe(
        catchError(this.handleError('login', body))
      );
  }

  update_invoice (model:any): Observable<any> {
    let body = JSON.stringify(model); 
    return this.http.post<any>(this.post_url + 'update_invoice', body, httpOptions)
      .pipe(
        catchError(this.handleError('login', body))
      );
  }

  update_product (model:any): Observable<any> {
    let body = JSON.stringify(model); 
    return this.http.post<any>(this.post_url + 'update_product', body, httpOptions)
      .pipe(
        catchError(this.handleError('login', body))
      );
  }
  delete_product(model:any): Observable<any> {
    let body = JSON.stringify(model); 
    return this.http.post<any>(this.post_url + 'delete_product', body, httpOptions)
      .pipe(
        catchError(this.handleError('login', body))
      );
  }


  getHeroes (): Observable<Hero[]> {
    return this.http.get<Hero[]>(this.admin_url + 'users',httpOptions)
      .pipe(
        catchError(this.handleError('getHeroes', []))
      );
  }

  getLocal (): Observable<Zero[]> {
    return this.http.get<Zero[]>(this.admin_url + 'users',httpOptions)
      .pipe(
        catchError(this.handleError('getLocal', []))
      );
  }

}