import { Component, OnInit,Renderer2, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../services/data.service';
import { items } from './model';





@Component({
  selector: 'app-report-sales',
  templateUrl: './sales.html',
  styleUrls: ['./sales.css']
})
export class ReportsalesComponent implements OnInit {

  view : boolean = false;
  post_invo = new items(1,[]);
  data : any [] =[];
  pdtlist : any [] = [];
  
  ia : number = 1;
  tot_qty : number = 0;
  total_val : number = 0;
  constructor(public ds : DataService,
     private renderer: Renderer2, private cdr: ChangeDetectorRef  ) {

    
   }  
 

 

  ngOnInit(): void {

   

       
    
    this.get_data();
  

  }

  get_data()
  {
    this.ds.sales_report(this.ds.report_demo)
    .subscribe((jsonData) => { this.getjson(jsonData)
                     },(err) => console.error(err),
                   
                     );
 
  }
  getjson(json:any)
  {





    console.log('js',json);
    this.data = json;
    this.auto_push();

    this.post_invo.si = 1
    this.post_invo.item[0].QTY = this.data[0].QTY;
    this.post_invo.item[0].NAME = this.data[0].NAME;
    this.pdtlist[0] = this.data[0].NAME;
    this.post_invo.item[0].PRICE = this.data[0].PRICE;
    this.post_invo.item[0].UNIT = this.data[0].UNIT;
    this.post_invo.item[0].NET_VALUE = this.data[0].PRICE * this.data[0].QTY;
    this.post_invo.item[0].SI = 1;


    this.express(this.data);



 






  }


  express(data:any)
  {
      
   
    for(var ix = 1; ix < data.length ; ix++)
    {
      

    var x = this.pdtlist.indexOf(data[ix].NAME,0)
    console.log('id',x)
    if(x < 0)
    {
     this.pdtlist[this.ia] = data[ix].NAME;
     
     this.auto_push();
     this.post_invo.item[this.ia].QTY = this.data[ix].QTY;
     this.post_invo.item[this.ia].NAME = this.data[ix].NAME;
     this.pdtlist[this.ia] = this.data[ix].NAME;
     this.post_invo.item[this.ia].PRICE = this.data[ix].PRICE;
     this.post_invo.item[this.ia].UNIT = this.data[ix].UNIT;
     this.post_invo.item[this.ia].NET_VALUE = this.data[ix].PRICE * this.data[ix].QTY;
     this.post_invo.item[this.ia].SI = this.ia+1;
     this.ia = this.ia + 1;
    

    }



    else
    {

      this.post_invo.item[x].QTY = this.post_invo.item[x].QTY +  this.data[ix].QTY;
     
          

    }


    }

   

    for(var ixc = 0; ixc < this.post_invo.item.length ; ixc++)
    {
      this.tot_qty =  this.tot_qty  + this.post_invo.item[ixc].QTY;
      this.total_val =  this.total_val + this.post_invo.item[ixc].NET_VALUE;
    }  

    this.auto_push();
    this.auto_push();



    
   
    this.post_invo.item[this.ia+1].NAME = 'Total';
    this.post_invo.item[this.ia+1].QTY = this.tot_qty;
    this.post_invo.item[this.ia+1].NET_VALUE = this.total_val;


    this.view = true;

  } 
  auto_push()
  {
        this.post_invo.item.push({ SI:null,BAR_CODE:null,ENTRY_NO:null,ID:null,MRP:null,NAME:null,PRICE:null,QTY:null,UNIT:null,NET_VALUE:null})
  }
   
  }



  







  



