import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Router } from '@angular/router';
import {AngularMyDatePickerDirective, DefaultView, IAngularMyDpOptions,IMyInputFieldChanged, 
  IMyDateModel, IMyMarkedDate, CalAnimation} from  'angular-mydatepicker';
  import { exp_invo } from './model';
  import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-expense-update',
  templateUrl: './main.html',
  styleUrls: ['./main.css']
})

export class Expense_update_ implements OnInit {


  public inputText: string = "";
  public validDate: boolean = false;
  catForm: FormGroup;
  party_add_Form : FormGroup;
  modal : any;
  exp_entry_no : number;
  model_2 : any;
  is_cash_date_edit : number = 0;
  is_party_edit : number = 1;
  cats : any [] = [];
  is_cat_edit : number = 0;
  arrayOfCusValues : any[] = [];
  exp_invos = new exp_invo(1,'Purchase',0,[],'','',0,0,0,0,0,new Date(),'','');

  public myDatePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    firstDayOfWeek: 'mo',
    sunHighlight: true,
    markCurrentDay: true,
    alignSelectorRight: false,
    openSelectorTopOfInput: false,
    minYear: 2019,
    maxYear: 2200,
    showSelectorArrow: true,
    monthSelector: true,
    yearSelector: true,
    satHighlight: false,
    highlightDates: [],
    disableDates: [],
    disableHeaderButtons: true,
    showWeekNumbers: false,
    disableDateRanges: [
      {begin: {year: 2016, month: 10, day: 5}, end: {year: 2016, month: 10, day: 7}},
      {begin: {year: 2016, month: 10, day: 10}, end: {year: 2016, month: 10, day: 12}}
    ],
    disableUntil: {year: 0, month: 0, day: 0},
    disableSince: {year: 2022, month: 2, day: 2},
    disableWeekdays: [],
    markDates: [],
    markWeekends: <IMyMarkedDate>{},
    selectorHeight: '200px',
    selectorWidth: '220px',
    closeSelectorOnDateSelect: true,
    closeSelectorOnDocumentClick: true,
    showMonthNumber: true,
    appendSelectorToBody: false,
    focusInputOnDateSelect: true,
    dateRangeDatesDelimiter: " - ",
    defaultView: DefaultView.Date,
    showFooterToday: false,
    calendarAnimation: {in: CalAnimation.ScaleCenter, out: CalAnimation.Fade},
    rtl: false,
    stylesData:
      {
        selector: '',
        styles: ''
      }
  };

    constructor(private fb: FormBuilder , private ds: DataService, private router: Router) { }

  

    ngOnInit(): void {

      this.catForm = this.fb.group({ 

        Category_name : ['', [<any>Validators.required, <any>Validators.minLength(3)]],
        
        
      });
   
      this.modal = document.getElementById("myModal");

      this.party_add_Form = this.fb.group({ 

        party_name : ['', [<any>Validators.required, <any>Validators.minLength(3)]],
        party_address : '',
        party_phone : ''
        
        
      });
  
    

      this.get_all_category()
      this.get_all_third_party()
      this.get_specific_expense()
      
      
    }

    change_edit_cat()
    {
      this.is_cat_edit = 0;
    }

    get_specific_expense()
    {
        this.ds.get_specific_expense()
        .subscribe((jsonData) => { this.getjson_specific_expense(jsonData)
                        },(err) => console.error(err)
                        
                        );
    }

    change_edit_date()
    {
      this.is_cash_date_edit = 1;
    }

    change_edit_party()
    {
      this.is_party_edit = 0;
    }

    onInputFieldChanged(event: IMyInputFieldChanged): void {
      //  console.log('onInputFieldChanged(): Value: ', event.value, ' - dateFormat: ', event.dateFormat, ' - valid: ', event.valid);
        this.validDate = event.valid;
        this.inputText = event.value;
      }

      onDateChanged(event: IMyDateModel): void {

        console.log('onDateChanged(): ', event);
       
        this.exp_invos.DATE = event.singleDate.jsDate;
         
         
           }

           openCity(evt, cityName) {

            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
              tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
              tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(cityName).style.display = "block";
            evt.currentTarget.className += " active";
          }

          add_party()
{

 this.modal.style.display = "block";

}
close_modal()
{
 this.modal.style.display = "none";

// this.get_third_party();
}
cus_blur(cus:any)
{
  this.exp_invos.VENDOR = cus;
}
get_all_third_party()
{
  this.ds.get_all_third_party()
  .subscribe((jsonData) => { this.getjson_get_party(jsonData)
                  },(err) => console.error(err)
                  
                  );
}



getjson_get_party(json:any)
{

  console.log('tp',json)
  this.arrayOfCusValues = json;
}

get_all_category()
{
  this.ds.get_all_category()
  .subscribe((jsonData) => { this.getjson_get_cat(jsonData)
                  },(err) => console.error(err)
                  
                  );
}

getjson_get_cat(js:any)
{
  if(js.sucess == true)
  {
    for(var i=0;i<js.cats.length;i++)
    {
      this.cats[i] = js.cats[i].CATEGORY_NAME;
    }
    
  }


}

getjson_specific_expense(json:any)
{
  console.log('data',json);
  this.model_2 = json.EXPENSE_DATE;

  this.exp_invos.REMARKS = json.REMARKS;
  this.exp_invos.VENDOR = json.PARTY_NAME;
  this.exp_invos.BILL_NO = json.VOUCHER_NO;
  this.exp_invos.GRAND_TOTAL = json.AMOUNT;
  this.exp_invos.E_CATEGORY = json.CATEGORY_NAME;
  this.is_party_edit = 1;
  this.is_cat_edit = 1;
  this.exp_invos.E_ENTRY_NUMBER = json.ID;
  
}

change_opt(bi : any)
{
  this.exp_invos.E_CATEGORY = bi;
}



update_expense()
{

  console.log('dd',this.exp_invos);
  this.ds.update_expense(this.exp_invos)
  .subscribe((jsonData) => { this.getjson_exp_y(jsonData)
                  },(err) => console.error(err)                  
                  );

}

getjson_exp_y(json:any)
{
  alert(json.msg);
  this.router.navigate(['/home']);
}



          
  
      
}  
