import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder }  from '@angular/forms';

import { DataService } from '../services/data.service';
import { Zero } from '../services/model';

import { AuthGuard } from '../services/auth.guard';
@Component({
  selector: 'app-home',
  templateUrl: './login.html',
  styleUrls: ['./login.css']
})
export class LoginComponent implements OnInit {
 

 
   loginForm: FormGroup;
   isclicked : boolean= false;
   products = [];
   heroes: Zero[];
  constructor( private router: Router,private fb: FormBuilder, private Ds : DataService,public Ag : AuthGuard ) {   }
  ngOnInit()  {
     this.isclicked=false;
   this.loginForm = this.fb.group({
            username: [''],
            password: ['']             
                                      });

  this.getHeroes();

  }
  
  getHeroes(): void {
    this.Ds.getLocal()
      .subscribe(heroes => (this.heroes = heroes));
      
  }


  login(){
  
  this.isclicked = true;



  
  this.Ds.login(this.loginForm.value)
   .subscribe((jsonData) => { this.getjson(jsonData)
                    },(err) => console.error(err),
                  
                    );

  }


   
  getjson(json :any)
  {
    
    console.log('json',json)
    if (json.success === false)
    {
       alert(json.msg);
 
    }
 
    else  
    {
      if(json.msg === 'user logged')
      {
     
       this.router.navigate(['/dash']);
 
      }
      else if(json.msg === 'Admin logged')
      {
     
       this.router.navigate(['/home']);
       this.Ag.token = true;
 
      }
   
    }
 
   


   
  }
}

