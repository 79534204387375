<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#/home">Home</a></li>
      <li class="breadcrumb-item"><a href="#/home">Expense</a></li>
      <li class="breadcrumb-item active" aria-current="page">Expense Edit</li>
    </ol>
  </nav>
  
  


  


   
<section  style="width:100%; height:790px;display:inline-block;">
    <div class="col-sm-12">
  
        <section  style="width:100%;display:inline-block;text-align: center;height: 25px;">
  
  <h2 style="font-size: 22px;
  font-weight: bold;
  background-color:#ae97b5">       Expence Record No : {{exp_entry_no}}  </h2>
   
  </section>
  
  
  
  
  <section id="non-printable" style="width:100%;display:inline-block;">
    
     <div class="col-md-12" style="height: 20px;">
     </div>
  
    <div class="row">
  

      <div *ngIf="is_cash_date_edit === 0" class="col-md-3">

      
        <label>  Date  : 
         {{model_2 | date:'dd-MM-yyyy hh:mm:ss a':'+0530'}} </label>

         <button  *ngIf="is_cash_date_edit === 0" type="button" 
         (click)="change_edit_date()" class="btn btn-default btn-sm">
        <span class="glyphicon glyphicon-pencil"></span> Edit
      </button>
          
         </div>
      
       
  
  
            <div *ngIf="is_cash_date_edit === 1" class="col-md-3">
            <label> Date : </label> 
           <!-- <div style="display:inline-block;">
           
            <mat-form-field>
             <input matInput [matDatepicker]="to_date"  placeholder="Select Bill Date" (dateInput)="cat_to_date(to_date)">
             <mat-datepicker-toggle matSuffix [for]="to_date"></mat-datepicker-toggle>
             <mat-datepicker #to_date></mat-datepicker>
           </mat-form-field>
  
           </div> -->

           <div  class="input_grove">
                                           
  
  
            <input  placeholder="{{!myDatePickerOptions.dateRange ?
              'Select a date' : 'Select a date range'}}" angular-mydatepicker name="mydate"
                [(ngModel)]="model_2" [options]="myDatePickerOptions" 
                 #dp="angular-mydatepicker"
                (dateChanged)="onDateChanged($event)" 
                
                (inputFieldChanged)="onInputFieldChanged($event)"
               [attr.disabled]="false" autocomplete="off"/>

               <div class="input-group-append">
                      <button type="button" class="btn btn-secondary" (click)="dp.clearDate()">
                            <img src="../../assets/icons/close.svg" style="width: 15px; height: 15px;" />
                      </button>
                    </div> 
                    <div class="input-group-append">
                      <button type="button" class="btn btn-secondary" (click)="dp.toggleCalendar()">
                            <img src="../../assets/icons/calendar.svg" style="width: 15px; height: 15px;" /> 
                      </button>
                    </div>
      
     
       
           </div>

           </div>
  
        <div  *ngIf="is_cat_edit === 0" class="col-md-4">
  
  
  <label for="Category_name">Expence Type</label>
   <div class="form-group" style="margin-left:3%;">
           <select #option class="form-control" style="max-width: 315px;" (change)="change_opt(option.value)" id="unit">
                                   
                                                 <option *ngFor="let cat of cats" [value]="cat">{{cat}}</option>
                                                </select>
     </div>
  
   </div>


   <div  *ngIf="is_cat_edit === 1" class="col-md-4">
  
  
    <label for="Category_name">Expence Type  : </label>
     
    <label>  {{this.exp_invos.E_CATEGORY}} </label>


    <button  *ngIf="is_cat_edit === 1" type="button" 
    (click)="change_edit_cat()" class="btn btn-default btn-sm">
   <span class="glyphicon glyphicon-pencil"></span> Edit
 </button>


     </div>
  
  
  
  
    <div class="col-md-4" *ngIf="is_party_edit === 0">
        <label for="Category_name">Party Name</label>
           <input ngui-auto-complete #autos  type="text" class="form-control" 
               style="width:320px;font-size: 18px;border-color:#52c3bb;"  [source]="arrayOfCusValues"    
              placeholder="Beneficiary Name"    
                (blur)="cus_blur(autos.value)"
                list-formatter="(PARTY_ADDRESS) PARTY_NAME"
                display-property-name="PARTY_NAME"> 

              
  
  
    </div>

    <div class="col-md-4" *ngIf="is_party_edit === 1">
      <label for="Category_name">Party Name</label>
         <input  type="text" class="form-control"  [(ngModel)]="exp_invos.VENDOR"
         style="width:320px;font-size: 18px;border-color:#52c3bb;"   >

         <button  *ngIf="is_party_edit === 1" type="button" 
         (click)="change_edit_party()" class="btn btn-default btn-sm">
        <span class="glyphicon glyphicon-pencil"></span> Edit
      </button>

    </div>
  
  
             
   
  
  
    <div class="col-xs-1"> </div>
  </div>
  
  
  <div class="row">
     
   
    <div class="col-xs-2" style="margin-left: 1%;">
        <label for="bill_no">Vocher/Bill number</label>
        <input id="bill_no"   [(ngModel)]="exp_invos.BILL_NO" type="text" class="form-control" placeholder="Bill No">
    </div>
   
         <div class="col-xs-1"> 
            
         </div>    
  
  
    <div class="col-xs-2" style="margin-left: 1%;">
        <label for="grant_amt">Amount</label>
        <input id="grant_amt"   [(ngModel)]="exp_invos.GRAND_TOTAL" type="text" class="form-control" placeholder="Amount">
    </div>
   
   <div class="col-md-3" style="margin-left: 1%;">
        <label for="remarks">Remarks</label>
        <input id="remarks"   [(ngModel)]="exp_invos.REMARKS" type="text" class="form-control" placeholder="Remarks">
    </div>
  
  
  
  
  </div>
  
  <button style="margin: 20px;" class="btn btn-primary pull-right"
   (click)="update_expense()" type="button"><span class="glyphicon glyphicon-ok"></span> Update </button>
  
  
  </section>
  
  
  
  
       </div>
  </section>







  
  
  
  
  
  