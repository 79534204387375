
<section id="non-printable" style="height: 35px;
margin-bottom: 0px;
margin-top: -12px;">


<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="#/home">Home</a></li>
    <li class="breadcrumb-item"><a href="#/home">Edit invoices</a></li>
    
  </ol>
</nav>


</section>


<app-bill-head-update id="non-printable"></app-bill-head-update>





<section id="non-printable"  style="width: 100%;height: 70px;">



    <table style="width:100%;font-family: monospace;color:#eae4e4;             
    font-size: 11pt;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    background-color: #545454;
    border-collapse: collapse;">
       <tr>
          
           <th>Bar Code</th>  
           <th>Item Name</th>  
           <th>Quantity</th>                   
           <th>Price</th>     
            <th> + </th>                 
  
  
         </tr>
  
         <tr style="color:snow;background-color: #a6d5d8;">
  
           
  
         <td class="nguui" height="10" style="width:15%;color: #545454;"> 
           <input ngui-auto-complete #autos type="text" name="autos" style="width: 230px; 
           height: 25px;
           border-color: #52c3bb;
           display: inline-block;
           background-color: unset;
           "class="form-control"  [source]="arrayOfKeyValues"  
        placeholder="barcode"      
         (keyup)="change_item_name(autos.value)" 
         list-formatter=" BAR_CODE -- DESCRIPTION"
         loading-text = "loading"
         display-property-name="BAR_CODE"
          [(ngModel)]="pro_barcode"> </td>
  
          <td height="10" style="width:18%;">   
           <input #item_name_model [disabled]="true"  style="text-align:center;border-radius: 6px;width: 90%;
           background-color: unset;border-color: #52c3bb;color: black;"  type="text" placeholder="Item name" 
        [(ngModel)]="pro_name">   </td>
  
          <td height="10" style="width:8%;">   
           <input #item_qty_model style="text-align:center;border-radius: 6px;width: 80%;
           background-color: unset;border-color: #52c3bb;color: black;"  type="number" placeholder="Quantity" 
         [(ngModel)]="pro_qty">   </td>
  
  
  
             <td height="10" style="width:5%;color: black;">         {{pro_mrp}}    </td>
  
        
  
  
               
         
          <td height="10" style="width:12%;">  
              <input type="number" style="width: 98%; text-align:center;font-family: cursive; 
              background-color: unset;border-color: #52c3bb;
           border-radius: 6px;" #box_net   [(ngModel)]="pro_price"                     
            
           size="10" placeholder=" " >  
         
         </td>
  
  
  <td class="td_button" height="10" style="width:12%;"> 
  
  <button [disabled]="push_disabled"  (click)="pushItem()" (keydown.ArrowDown)="pushItem_down()"  type="button" class="self">
            Add
          </button> 
  
  
  
  </td>
  
  
         </tr>
  
  
  
  </table>
  </section>
   




  
<section id="non-printable" style="background-color: #656565;color: #fffc9e;height: auto;width:100%;">




  
    <table  *ngIf="ia>0" style="    width: 100%;
     color: #dfd9d9;
     font-size: 10pt;
     font-style: normal;
     text-align: center;
     border-collapse: collapse;">
       <tr style="color: #1f5;" >
           <th>SI</th>
          
           <th>Description</th>
           
           
            <th>Unit</th>
           <th>Quantity</th>
           
           
           <th>price</th>
           <th>Net price</th>
           <th> Edit  </th>
           <th> Delete </th>
    
    
         </tr>
       <tr *ngFor="let item of ds.i_m.items; let k=index " height="10">
         <td height="15" style="width:5%;">   {{item.SI_NO}} </td>
    
    
         <td> {{item.NAME}}
    
         </td>
    
       
       <td> {{item.UNIT}}
    
         </td>
         <td> {{item.QTY | number : '1.2-2'}}
    
       </td>
      
       <td> {{item.PRICE  | number : '1.2-4' }}
    
       </td>
       <td> {{item.NET_PRICE | number : '1.2-2'}}
    
       </td>
    
    
    
    
    
         <td height="8" style="width:5%;" class="tds_plus"> 
       
    
         <button (click)="editItem(k)" (keydown.ArrowDown)="editItem(k)"
          class="btn-outline-dark" style="background-color:violet"> 
              <span class="navbar-toggler-icon"> 
             <img src="../../assets/icons/edit.svg" style="width: 15px;
           height: 18px;" /> </span>
           </button>
       
       
       </td>
    
         <td height="8" style="width:5%;" class="tds_plus" >  
         <button (click)="RemoveItem(k)" class="btn-outline-dark"  style="background-color:salmon" >  
            <span class="navbar-toggler-icon"> 
             <img src="../../assets/icons/delete_dob.svg" style="width: 15px;
           height: 18px;" /> </span></button>
    
         </td>
       
       </tr>
    
     </table>
    
    
     
     <div class="footer">
    
    
      <table    style="width:100%;margin-top:10px;text-align: center;">
        <tr style="    background-color: #306737;
        color: gold;">
           
          <th> Grand Total</th>
          <th> Amt Payed</th>
          <th> Bal Due </th>
          <th> Update & Print </th>
    
        </tr>
    
        <tr style="background-color: midnightblue;color: snow;">
         
          <td>{{ this.ds.i_m.tails.GRAND_TOTAL | number : '1.2-2'}}</td>
         
          <td> <input #disamt type="number" name="payed_amt"  (keyup)="change_dit()"
            [(ngModel)]="this.ds.i_m.tails.PAYED_TODAY" 
            style="text-align: center;border-radius: 5px;width:110px;" > </td>
    
         
    
          <td>{{ this.ds.i_m.tails.BAL_PAY | number : '1.2-2'}}</td>
         
    
          
    
          <td class="save_td">
              <button [disabled]="push_disabled_enter" style="width: 150px;margin-left: 10px;" type="button" class="btn btn-primary"
               (click)="save_invoice()">
           
                  > Enter
                 
               </button>
          </td>
    
    
        </tr>   
    
    
      </table>
    
    
     
    </div>
    
    
    </section>
    
    

<div id="natives" class="modal">


    <!-- Modal content -->
    <div class="modal-content">
        <label  class="btn btn-primary" >
           Edit Item
        </label> 
  
        <div class="row">
  
          
          <div class="col-sm-3">
  
            <div class="input-group input-group-sm mb-6">
                     <div class="input-group-prepend">
                      <span class="input-group-text" id="inputGroup-sizing-sm"> Qty </span>
                     </div>
                  <input type="text" class="form-control" [(ngModel)]="pro_qty_model" 
                   aria-label="Small" aria-describedby="inputGroup-sizing-sm">
                </div>
  
   
          </div>
  
          <div class="col-sm-3">
  
            <div class="input-group input-group-sm mb-6">
                     <div class="input-group-prepend">
                      <span class="input-group-text" id="inputGroup-sizing-sm">price</span>
                     </div>
                  <input type="text" class="form-control" [(ngModel)]="pro_np_model"  aria-label="Small" aria-describedby="inputGroup-sizing-sm">
                </div>
          </div>
          
  </div>   
  
  <br>
       
  
          <button  [disabled]="push_disabled_enter" style="width: 150px;margin-left: 10px;" type="button" class="btn btn-primary" (click)="close_modal()">
            Update & close
          </button>
  
    </div>
  
  </div> 
  
  
  
  <app-print-eighty id="printable">  </app-print-eighty>  