import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-about',
  templateUrl: './about.html',
  styleUrls: ['./about.css']
})

export class Aboutcomponent implements OnInit {

    constructor() { }

  

    ngOnInit(): void {
    }
}  
