export class items {
    constructor(
      
           public si : number ,
           public item : sublist[],
          
         
        
      
    ) {  }
  }
  
  
  export class sublist 
  {
   constructor(
  public SI : number,
  public BAR_CODE: string,
  public ENTRY_NO: number,
  public ID: number,
  public MRP: number,
  public NAME: string,
  public PRICE: number,
  public QTY: number,
  public UNIT: string,
  public NET_VALUE : number


  
    ) {  }
  }
  