<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="#/home">Home</a></li>
      <li class="breadcrumb-item"><a href="#/home">Expense</a></li>
      <li class="breadcrumb-item active" aria-current="page">Expense Entry</li>
    </ol>
  </nav>
  
  

  <div class="tab">

    <!-- <button class="tablinks" (click)="openCity($event, 'London')" >Purchase Entry</button> -->
    <button class="tablinks" (click)="openCity($event, 'Paris')" id="defaultOpen">Add an expense</button>
    <button class="tablinks" (click)="openCity($event, 'Tokyo')">Add New Category</button>
  <!--   <button class="tablinks" (click)="openCity($event, 'Delhi')">Edit An Expense</button> -->
  </div>
  


   
<section id="Paris" class="tabcontent" style="width:100%; height:790px;display:inline-block;">
    <div class="col-sm-12">
  
        <section  style="width:100%;display:inline-block;text-align: center;height: 25px;">
  
  <h2 style="font-size: 22px;
  font-weight: bold;
  background-color:#ae97b5">       Expence Record No : {{exp_entry_no}}  </h2>
   
  </section>
  
  
  
  
  <section id="non-printable" style="width:100%;display:inline-block;">
    
     <div class="col-md-12" style="height: 20px;">
     </div>
  
    <div class="row">
  
    
  
            <div class="col-md-3">
            <label> Date : </label> 
           <!-- <div style="display:inline-block;">
           
            <mat-form-field>
             <input matInput [matDatepicker]="to_date"  placeholder="Select Bill Date" (dateInput)="cat_to_date(to_date)">
             <mat-datepicker-toggle matSuffix [for]="to_date"></mat-datepicker-toggle>
             <mat-datepicker #to_date></mat-datepicker>
           </mat-form-field>
  
           </div> -->

           <div  class="input_grove">
                                           
  
  
            <input  placeholder="{{!myDatePickerOptions.dateRange ?
              'Select a date' : 'Select a date range'}}" angular-mydatepicker name="mydate"
                [(ngModel)]="model_2" [options]="myDatePickerOptions" 
                 #dp="angular-mydatepicker"
                (dateChanged)="onDateChanged($event)" 
                
                (inputFieldChanged)="onInputFieldChanged($event)"
               [attr.disabled]="false" autocomplete="off"/>

               <div class="input-group-append">
                      <button type="button" class="btn btn-secondary" (click)="dp.clearDate()">
                            <img src="../../assets/icons/close.svg" style="width: 15px; height: 15px;" />
                      </button>
                    </div> 
                    <div class="input-group-append">
                      <button type="button" class="btn btn-secondary" (click)="dp.toggleCalendar()">
                            <img src="../../assets/icons/calendar.svg" style="width: 15px; height: 15px;" /> 
                      </button>
                    </div>
      
     
       
           </div>

           </div>
  
        <div class="col-md-4">
  
  
  <label for="Category_name">Expence Type</label>
   <div class="form-group" style="margin-left:3%;">
           <select #option class="form-control" style="max-width: 315px;" (change)="change_opt(option.value)" id="unit">
                                   
                                                 <option *ngFor="let cat of cats" [value]="cat">{{cat}}</option>
                                                </select>
     </div>
  
   </div>
  
  
  
  
    <div class="col-md-4">
        <label for="Category_name">Party Name</label>
           <input ngui-auto-complete #autos  type="text" class="form-control" 
               style="width:320px;font-size: 18px;border-color:#52c3bb;"  [source]="arrayOfCusValues"    
              placeholder="Beneficiary Name"    
                (blur)="cus_blur(autos.value)"
                list-formatter="(PARTY_ADDRESS) PARTY_NAME"
                display-property-name="PARTY_NAME"> 
  
  
    </div>
  
  
             
    
            <div class="col-md-1">
                Add party
              <button mat-fab class="add_but" style="background-color: cadetblue;
             font-size: 24px;"  (click)="add_party()">+</button>
  
            </div>
  
  
    <div class="col-xs-1"> </div>
  </div>
  
  
  <div class="row">
     
   
    <div class="col-xs-2" style="margin-left: 1%;">
        <label for="bill_no">Vocher/Bill number</label>
        <input id="bill_no"   [(ngModel)]="exp_invos.BILL_NO" type="text" class="form-control" placeholder="Bill No">
    </div>
   
         <div class="col-xs-1"> 
            
         </div>    
  
  
    <div class="col-xs-2" style="margin-left: 1%;">
        <label for="grant_amt">Amount</label>
        <input id="grant_amt"   [(ngModel)]="exp_invos.GRAND_TOTAL" type="text" class="form-control" placeholder="Amount">
    </div>
   
   <div class="col-md-3" style="margin-left: 1%;">
        <label for="remarks">Remarks</label>
        <input id="remarks"   [(ngModel)]="exp_invos.REMARKS" type="text" class="form-control" placeholder="Remarks">
    </div>
  
  
  
  
  </div>
  
  <button style="margin: 20px;" class="btn btn-primary pull-right"
   (click)="save_expense()" type="button"><span class="glyphicon glyphicon-ok"></span> Done</button>
  
  
  </section>
  
  
  
  
       </div>
  </section>



  
  <section id="Tokyo" class="tabcontent" style="width:100%; height:790px;display:inline-block;">
    
    <div class="col-sm-12">


<div class="col-sm-6"  style="background-color: #e0e0e0"> 

<div style="margin-left: 22%">
<u> <h4>Add a New Expence Category</h4> </u>
</div>


<div>



<form [formGroup]="catForm">
<div class="form-group fm">
  <label for="Category_name">Categoty Name</label>
  <input id="Category_name"  formControlName="Category_name" type="text" class="form-control" placeholder="Category  Name">
   <small [hidden]="catForm.controls.Category_name.valid" class="text-danger">
                                           Catogory Name is required (minimum 3 characters).
                                     </small>
</div>


 



</form>


</div>
<button class="btn btn-primary pull-right" (click)="add_cat()" type="button"><span class="glyphicon glyphicon-ok"></span> Done</button>
</div>



   </div>



   
</section>


<div id="myModal" class="modal">


  <!-- Modal content -->
  <div class="modal-content">
      <label  class="btn btn-primary" >
          Add Third Party
      </label> 




        <form [formGroup]="party_add_Form">
             <div class="form-group fm">
              <label>Party Name</label>
              <input   formControlName="party_name"  type="text" class="form-control"
               placeholder="Party Name">
               <small [hidden]="party_add_Form.controls.party_name.valid" class="text-danger">
                                                         Name is required (minimum 3 characters).
                                                 </small>
            </div>

              <div class="form-group fm">
              <label>Party Address</label>
              <input   formControlName="party_address"  type="text" class="form-control"
               placeholder="Address">
              
            </div>

              <div class="form-group fm">
              <label for="cat_name">Party Phone No</label>
              <input   formControlName="party_phone"  type="number"  maxlength="12" class="form-control"
               placeholder="Phone">
              
            </div>

            <button  style="width: 150px;" type="button" class="btn btn-primary" (click)="add_third_party()">
                Add
              </button> 

              <button style="width: 150px;margin-left: 10px;" type="button" class="btn btn-primary" (click)="close_modal()">
                  Cancel
                </button>
        
            </form>


  </div>

</div>
  
  
  
  
  
  